import styles from './criteria_management.module.css'
import { ButtonSecondary } from "../../../components/button/button";
import ToolTipIcon from "../../../components/tooltip_icon/tooltip_icon";
import { LoanCalc } from "../../../helpers/loan";
import { LenderLoanCriteria } from "../../../helpers/user";
import CriteriaView from "../critieria_view/criteria_view";
import React, { useEffect, useRef, useState } from 'react';

const CriteriaManagement = ({initialCriteria, onCriteriaChange, secondaryButton}: {initialCriteria?: LenderLoanCriteria[], onCriteriaChange: (criteria: LenderLoanCriteria[])=>void, secondaryButton?: JSX.Element}) => {
    const [criteria, setCriteria] = useState<LenderLoanCriteria[]>(initialCriteria ? initialCriteria : []);
    const criteriaContainerRef = useRef<HTMLDivElement | null>(null);
    
    useEffect(()=>{
        if(onCriteriaChange) {
            onCriteriaChange(criteria);
        } 
    }, [criteria, onCriteriaChange])

    useEffect(()=>{
        if(initialCriteria) {
            if(initialCriteria !== criteria) {
                setCriteria(initialCriteria);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialCriteria])

    const AddLoanCriteriaHandler = () => {
        let tmpCriteria: LenderLoanCriteria[] = [...criteria]
        let newCriteria: LenderLoanCriteria = {
            minimumRiskRating: 0,
            maximumRiskRating: 850,
            cashLendable: 50000,
            minimumTerm: 1,
            maximumTerm: 24,
            portionWillingToFund: 100000
        }
        tmpCriteria.push(newCriteria)
        setCriteria(tmpCriteria);
    }

    useEffect(() => {
        if (criteriaContainerRef.current) {
            criteriaContainerRef.current.scrollTop = criteriaContainerRef.current.scrollHeight;
        }
    }, [criteria]);

    const RemLoanCriteriaHandler = (index: number) => {
        let tmpCriteria = [...criteria];
        tmpCriteria.splice(index, 1);
        setCriteria(tmpCriteria);
    }

    const handleCriteriaChange = (e: React.ChangeEvent<HTMLInputElement>, idx: number) => {
        let { name, value } = e.target;
        let loanCriteria = [...criteria];
        // Parse the value to an integer
        let intValue = 0;
        if(!name.includes('cashLendable')) {
            intValue = parseInt(value, 10);
        }
        // Update the specific criteria while ensuring constraints
        if (name.includes('minimum')) {
            if (name === 'minimumRiskRating' && intValue > loanCriteria[idx].maximumRiskRating) {
                loanCriteria[idx] = { ...loanCriteria[idx], [name]: intValue, maximumRiskRating: intValue };
            } else if (name === 'minimumTerm' && intValue > loanCriteria[idx].maximumTerm) {
                loanCriteria[idx] = { ...loanCriteria[idx], [name]: intValue, maximumTerm: intValue };
            } else {
                loanCriteria[idx] = { ...loanCriteria[idx], [name]: intValue };
            }
        } else if (name.includes('maximum')) {
            if (name === 'maximumRiskRating' && intValue < loanCriteria[idx].minimumRiskRating) {
                loanCriteria[idx] = { ...loanCriteria[idx], [name]: intValue, minimumRiskRating: intValue };
            } else if (name === 'maximumTerm' && intValue < loanCriteria[idx].minimumTerm) {
                loanCriteria[idx] = { ...loanCriteria[idx], [name]: intValue, minimumTerm: intValue };
            } else {
                loanCriteria[idx] = { ...loanCriteria[idx], [name]: intValue };
            }
        } else {
            if(name.includes('cashLendable')) {
                if(!isNaN(value)) {
                    loanCriteria[idx] = { ...loanCriteria[idx], [name]: value * LoanCalc.scaleFactor};
                    const minPortionPercent = (50 / value) * 100000;
                    loanCriteria[idx] = { ...loanCriteria[idx], portionWillingToFund: minPortionPercent};
                }
            } else if(name.includes('portionWillingToFund')) {
                const currentlyApportioned = ((loanCriteria[idx].cashLendable * value) / LoanCalc.scaleFactor / 100);
                const minPortionPercent = (50 / loanCriteria[idx].cashLendable) * 100000;
                // ensure portion is always larger than $50
                if( currentlyApportioned >= 50) {
                    loanCriteria[idx] = { ...loanCriteria[idx], [name]: intValue * LoanCalc.scaleFactor};
                } else {
                    loanCriteria[idx] = { ...loanCriteria[idx], [name]: minPortionPercent * LoanCalc.scaleFactor};
                }
            } else {
                loanCriteria[idx] = { ...loanCriteria[idx], [name]: intValue };
            }
        }
        setCriteria(loanCriteria);
    }

    const handleCriteriaBlur = (e: React.FocusEvent<HTMLInputElement, Element>, idx: number) => {
        let { name, value } = e.target;
        let loanCriteria = [...criteria];
        if(name.includes('cashLendable')) {
            if(!isNaN(value)) {
                if(value < 50) {
                    value = 50;
                    //  also send willing to fund portion to 100% as the minimum is 50
                    const minPortionPercent = (50 / value) * 100000;
                    loanCriteria[idx] = { ...loanCriteria[idx], portionWillingToFund: minPortionPercent};
                }
                loanCriteria[idx] = { ...loanCriteria[idx], [name]: value * 1000};
            }
        }
        setCriteria(loanCriteria);
    }

    return (
        <div>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                <h1 style={{maxWidth: '90vw'}}>Total Lendable: ${criteria.reduce((sum, c)=>{return sum + c.cashLendable}, 0)/LoanCalc.scaleFactor}</h1>
                <div>
                    <ToolTipIcon>
                        <div style={{width: 'fit-content', textAlign: 'left', margin: 'auto'}}>
                            <p>Capital to lend to borrowers matching all criteria.</p>
                            <p>Maintain this balance in your account at all times.</p>
                            <br/>
                            <p>Withdraws will never exceed this amount.</p>
                            <p>Having a balance lower than this will result in a</p>
                            <p>failure to fund loans and automatic criteria removal.</p>
                            <br/>
                            <p>Each criteria is an automatic investing strategy.</p>
                            <p>Loans matching each individual criteria will be</p>
                            <p>automatically funded so you don't have to browse</p>
                            <p>the marketplace regularly.</p>
                        </div>
                    </ToolTipIcon>
                </div>
            </div>
            <div ref={criteriaContainerRef} className={styles.AllCriteriaContainer}>
                {criteria.map((c, i)=>{
                    return (
                        <div key={i}>
                            <CriteriaView 
                                criteria={c} 
                                idx={i} 
                                onCriteriaChangeHandler={handleCriteriaChange}
                                onCriteriaBlur={handleCriteriaBlur}
                                RemLoanCriteriaHandler={RemLoanCriteriaHandler}
                            />
                        </div>
                    );
                })}
            </div>
            
            <div className={styles.ButtonContainer}>
                <ButtonSecondary onClick={AddLoanCriteriaHandler}>Add Criteria</ButtonSecondary>
                {secondaryButton ? secondaryButton : null}
            </div>
        </div>
    );
}

export default CriteriaManagement;