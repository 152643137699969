import './progress_bar.css'
import React from 'react';

const ProgressBar = ({ progress, max }) => {
    const progressPercentage = (progress / max) * 100;

    return (
        <div className="progress-bar-container">
            <div
                className="progress-bar"
                style={{ width: `${progressPercentage}%` }}
            ></div>
        </div>
    );
};

export default ProgressBar;