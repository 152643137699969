import styles from './disclosures.module.css';
import React from "react";

const Disclosures = () => {
    return (
        <div className={styles.DisclosuresPage}>
            <div className={styles.DisclosureContainer}>
                <h2>Full Disclosure for Investors/Lenders</h2>
                <p>Borrower Payment-Dependent Notes (BPDNs) yields vary. Actual returns depend on borrower payments and other factors. This advertisement is not an offer to sell securities. Investing in BPDNs involves risks, including the potential loss of principal. Investors must meet certain qualifications and conditions. All investments are subject to approval. Terms and conditions apply. Submitting your information does not constitute an investment application or a commitment to invest.</p>
                <p>No money or other consideration is being solicited, and if sent, it will not be accepted. No offer to buy the securities can be accepted, and no part of the purchase price can be received until the offering statement filed with the SEC is qualified. Any such offer may be withdrawn or revoked, without obligation or commitment of any kind, at any time before notice of its acceptance is given after the qualification date. An indication of interest involves no obligation or commitment of any kind.</p>
                <p>The SEC has not approved or disapproved of these securities or determined if the offering statement is truthful or complete. Any representation to the contrary is a criminal offense. This communication may contain forward-looking statements that involve substantial risks and uncertainties. Actual results may differ materially from those indicated in the forward-looking statements.</p>
            </div>
            <div className={styles.DisclosureContainer}>
                <h2>Full Disclosure for Borrowers</h2>
                <p>The advertised interest rates and loan terms are based on borrower qualifications, including creditworthiness, income, and other factors. Not all applicants will qualify for the lowest rates. The terms and conditions of your loan will be provided to you if you are approved. This advertisement is not an offer to lend. All loans are subject to credit approval and must meet certain conditions.</p>
                <p>No money or other consideration is being solicited in connection with this advertisement. Submitting your information does not constitute a loan application or a commitment to provide a loan. Rates and loan terms are subject to change without notice and may vary based on individual circumstances.</p>
                <p>Borrowing involves risks, including the potential for increased debt and financial hardship. Please ensure you understand the terms of any loan you take out and consider seeking advice from a financial professional if you have questions or concerns.</p>
                <p>For more information, please review the loan agreement and terms provided during the application process.</p>
            </div>
            
        </div>
    );
}

export default Disclosures;