import styles from './lend.module.css';
import React, { useEffect, useRef, useState } from 'react';
import { PieChart, Pie, Tooltip as ChartTooltip, ResponsiveContainer } from 'recharts';
import { SignUpInModal } from '../../components/signin_up_modal/signin_up_modal.tsx';
import { User, getUserCookieData, UserImpl, LenderLoanCriteria, saveUserData } from '../../helpers/user.ts';
import { Loan } from '../../helpers/loan.ts';
import { sleep } from '../../helpers/sleep.ts';
import ProgressBar from '../../components/progress_bar/progress_bar.tsx';
import ToolTipIcon from '../../components/tooltip_icon/tooltip_icon.tsx';
import CriteriaManagement from './criteria_management/criteria_management.tsx';
import { ButtonSecondary } from '../../components/button/button.tsx';
import { SelectPrimary } from '../../components/select/select.tsx';
import { ErrorMessageModal, MessageModal } from '../../components/my_modal/my_modal.tsx';
import { useNavigate } from 'react-router-dom';
import MarketPlaceView from './marketplace_view/marketplace_view.tsx';
import { useLoadedAnalytics } from '../../helpers/analytics.tsx';

const LoanInfoCards = ({completedLoanInfoCardData, upcomingLoanInfoCardData, outstandingLoanInfoCardData}) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const intervalRef = useRef<number | null>(null);
    const startXRef = useRef<number | null>(null);

    useEffect(() => {
        startAutoRotation();
        return () => {
            if (intervalRef.current !== null) {
                clearInterval(intervalRef.current);
            }
        };
    }, []);

    const startAutoRotation = () => {
        intervalRef.current = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % 3);
        }, 5000);
    };

    const stopAutoRotation = () => {
        if (intervalRef.current !== null) {
            clearInterval(intervalRef.current);
        }
    };

    const handleDotClick = (index) => {
        setCurrentIndex(index);
        stopAutoRotation();
        startAutoRotation();
    };

    const handleCarouselClick = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % 3);
    }

    const handleTouchStart = (e: React.TouchEvent) => {
        startXRef.current = e.touches[0].clientX;
    };

    const handleTouchEnd = (e: React.TouchEvent) => {
        if (startXRef.current !== null) {
            const endX = e.changedTouches[0].clientX;
            const diffX = startXRef.current - endX;

            if (Math.abs(diffX) > 50) { // Threshold for swipe
                if (diffX > 0) {
                    // Swiped left
                    setCurrentIndex((prevIndex) => (prevIndex + 1) % 3);
                } else {
                    // Swiped right
                    setCurrentIndex((prevIndex) => (prevIndex - 1 + 3) % 3);
                }
                stopAutoRotation();
                startAutoRotation();
            }

            startXRef.current = null;
        }
    };

    const renderCards = () => {
        return (
            <>
                <div className={`carousel-item ${currentIndex === 0 ? 'active' : ''}`}>
                    <h2>Completed</h2>
                    <h3>Months:</h3>
                    <p>{completedLoanInfoCardData.months}</p>
                    <h3>Principal:</h3>
                    <p>${completedLoanInfoCardData.principal}</p>
                    <h3>Interest:</h3>
                    <p>${completedLoanInfoCardData.interest}</p>
                </div>
                <div className={`carousel-item ${currentIndex === 1 ? 'active' : ''}`}>
                    <h2>Upcoming</h2>
                    <h3>Pay Date:</h3>
                    <p>{upcomingLoanInfoCardData.payDate}</p>
                    <h3>Principal:</h3>
                    <p>${upcomingLoanInfoCardData.principal}</p>
                    <h3>Interest:</h3>
                    <p>${upcomingLoanInfoCardData.interest}</p>
                </div>
                <div className={`carousel-item ${currentIndex === 2 ? 'active' : ''}`}>
                    <h2>Outstanding</h2>
                    <h3>Months:</h3>
                    <p>{outstandingLoanInfoCardData.months}</p>
                    <h3>Principal:</h3>
                    <p>${outstandingLoanInfoCardData.principal}</p>
                    <h3>Interest:</h3>
                    <p>${outstandingLoanInfoCardData.interest}</p>
                </div>
            </>
        );
    };

    return (
        <div className='LenderLoanCarouselContainer' 
            onMouseEnter={stopAutoRotation} 
            onMouseLeave={startAutoRotation} 
            onClick={handleCarouselClick}
            onTouchStart={handleTouchStart}
            onTouchEnd={handleTouchEnd}
        >
            <div className="carousel">
                <div className="carousel-inner" style={{ transform: `translateX(-${currentIndex * 100}%)` }}>
                    {renderCards()}
                </div>
                <div className="carousel-dots">
                    {[0, 1, 2].map((dotIndex) => (
                        <div
                            key={dotIndex}
                            className={`carousel-dot ${currentIndex === dotIndex ? 'active' : ''}`}
                            onClick={() => handleDotClick(dotIndex)}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
}

const LoanViewCards = ({filteredLoans}) => {
    return (
        <div className='LenderLoanViewScrollContainer'>
            {filteredLoans?.map((loan: Loan, idx: number) => {
                const calculateDays = (start: string, end: string) => {
                    const startDate: any = new Date(start);
                    const endDate: any = new Date(end);
                    return Math.round((endDate - startDate) / (1000 * 60 * 60 * 24));
                };
                
                const totalDuration = calculateDays(loan.loanStartDate.slice(0, 10), loan.loanEndDate.slice(0, 10));
                const currentProgress = calculateDays(loan.loanStartDate, new Date().toISOString().split('T')[0]);
                const monthsProgressed = (() => {
                    const start = new Date(loan.loanStartDate);
                    const now = new Date();
                    return (now.getFullYear() - start.getFullYear()) * 12 + (now.getMonth() - start.getMonth());
                })();
                const upcomingPayDate = loan.expectedPayments
                    .map(payment => ({ ...payment, date: new Date(payment.date) }))
                    .filter(payment => payment.date > new Date())
                    .sort((a, b) => a.date.getTime() - b.date.getTime())[0]?.date.toISOString().split('T')[0];

                const monthsOutstanding = (() => {
                    const start = new Date(loan.loanStartDate);
                    const end = new Date(loan.loanEndDate);
                    const now = new Date();
                    const totalMonths = (end.getFullYear() - start.getFullYear()) * 12 + (end.getMonth() - start.getMonth());
                    const monthsPassed = (now.getFullYear() - start.getFullYear()) * 12 + (now.getMonth() - start.getMonth());
                    return totalMonths - monthsPassed;
                })();

                const totalPrincipalPaid = (loan.loanPaidInCents/1000) * (loan.portion/100000);
                const totalInterestPaid = ((loan.actualPayments.reduce((total, payment) => total + payment.interest, 0) / 1000) * (loan.portion/100000));

                const nextExpectedPrincipalPayment = ((loan.expectedPayments
                    .map(payment => ({ ...payment, date: new Date(payment.date) }))
                    .filter(payment => payment.date > new Date())
                    .sort((a, b) => a.date.getTime() - b.date.getTime())[0]?.principal / 1000)*(loan.portion/100000));

                const nextExpectedInterestPayment = ((loan.expectedPayments
                    .map(payment => ({ ...payment, date: new Date(payment.date) }))
                    .filter(payment => payment.date > new Date())
                    .sort((a, b) => a.date.getTime() - b.date.getTime())[0]?.interest / 1000) * (loan.portion/100000));

                const outstandingPrincipal = (() => {
                        const _totalPrincipalPaid = loan.actualPayments.reduce((total, payment) => total + payment.principal, 0);
                        const _outstandingPrincipal = (loan.loanTotalInCents - _totalPrincipalPaid) / 1000;
                        return (_outstandingPrincipal * (loan.portion/100000));
                    })()

                const outstandingInterest = (() => {
                        const _totalInterestPaid = loan.actualPayments.reduce((total, payment) => total + payment.interest, 0);
                        const _outstandingInterest = (loan.expectedInterestInCents - _totalInterestPaid) / 1000;
                        return (_outstandingInterest * (loan.portion/100000));
                    })()

                return (
                    <div className='LoanViewContainer' key={idx}>
                        <div className='ProgressContainer' data-tooltip-id="progress-bar-tooltip">
                            <ProgressBar progress={currentProgress} max={totalDuration} />
                        </div>
                        <ToolTipIcon>
                            {currentProgress < 0 ?
                                <p>{Math.abs(currentProgress)} Days until payments begin</p>
                                :
                                <p>{currentProgress}/{totalDuration} Days through loan term</p>
                            }
                        </ToolTipIcon>
                        <div className='TitleContainer'>
                            <h2>Funding {(loan.portion/1000).toFixed(2)}% of a ${(loan.loanTotalInCents / 1000).toFixed(2)} Loan</h2>
                            <p>(Exposure: ${(loan.portion/100000) * (loan.loanTotalInCents / 1000)})</p>
                            <h4>Loan Term: {(new Date(loan.loanEndDate).getFullYear() - new Date(loan.loanStartDate).getFullYear()) * 12 + (new Date(loan.loanEndDate).getMonth() - new Date(loan.loanStartDate).getMonth())} Months</h4>
                            <h4>Interest Rate: {(loan.loanInterestRate/1000).toFixed(2)}%</h4>
                            <h4>Expected Interest: ${((loan.portion/100000) * (loan.expectedInterestInCents / 1000)).toFixed(2)}</h4>
                        </div>

                        <div className='DataContainer'>
                            <LoanInfoCards completedLoanInfoCardData={{months: monthsProgressed, payDate: "", principal: totalPrincipalPaid, interest: totalInterestPaid}} 
                                upcomingLoanInfoCardData={{months: 0, payDate: upcomingPayDate, principal: nextExpectedPrincipalPayment, interest: nextExpectedInterestPayment}} 
                                outstandingLoanInfoCardData={{months: monthsOutstanding, payDate: "", principal: outstandingPrincipal, interest: outstandingInterest}} 
                            />

                            <div className='ChartContainer'>
                                <ResponsiveContainer>
                                    <PieChart>
                                        {/* total loan */}
                                        <Pie
                                            dataKey="value"
                                            data={[
                                                { name: 'Total Remaining', value: ((loan.portion/100000) * ((loan.loanTotalInCents - loan.loanPaidInCents) / 1000)), fill: "var(--bg-color-2)" },
                                                { name: 'Total Paid', value: ((loan.portion/100000) * (loan.loanPaidInCents / 1000)), fill: "var(--pos-color)" },
                                                { name: 'Next Payment', value: nextExpectedInterestPayment + nextExpectedPrincipalPayment, fill: "var(--cta-color)" }
                                            ]}
                                            cx="50%" cy="50%" outerRadius={40}
                                        />
                                        {/* Principal */}
                                        <Pie 
                                            dataKey="value"
                                                data={[
                                                { name: 'Principal Remaining', value: outstandingPrincipal, fill: "var(--bg-color-2)" },
                                                { name: 'Principal Paid', value: totalPrincipalPaid, fill: "var(--pos-color)" },
                                                { name: 'Next Principal', value: nextExpectedPrincipalPayment, fill: "var(--cta-color)" }
                                            ]} cx="50%" cy="50%" innerRadius={50} outerRadius={70} />
                                        {/* Interest */}
                                        <Pie 
                                            dataKey="value"
                                            data={[
                                                { name: 'Interest Remaining', value: outstandingInterest, fill: "var(--bg-color-2)" },
                                                { name: 'Interest Paid', value: totalInterestPaid, fill: "var(--pos-color)" },
                                                { name: 'Next Interest', value: nextExpectedInterestPayment, fill: "var(--cta-color)" }
                                            ]} cx="50%" cy="50%" innerRadius={80} outerRadius={100} />
                                        <ChartTooltip />
                                    </PieChart>
                                </ResponsiveContainer>
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    );
}

// TODO: temporary while service is being built
const Message = () => {
    const navigate = useNavigate();
    return (
        <div>
            <h2>Thank You for Your Interest</h2>
            <p style={{textAlign: 'left'}}>
                We appreciate your application, but as a small startup still in the early stages of development, 
                our peer-to-peer lending service is not yet operational. We are currently gauging market interest and need more users to 
                support the platform's full launch.
                </p>
            <h3>Your Impact</h3>
            <p style={{textAlign: 'left'}}>
                Your application brings us closer to reaching the critical number of users needed to make this service a reality. 
                Your time and effort are highly valued.
            </p>
            <h3>Invite & Earn</h3>
            <p style={{textAlign: 'left'}}>
                When you signed up, you received a referral code. By inviting others to join, you can earn monetary rewards. 
                Learn more on the <span style={{textDecoration: 'underline', cursor: 'pointer'}} onClick={()=>{navigate('/faq?q=referral')}}>FAQ page</span>.
            </p>
            <h3>In the Meantime</h3>
            <p style={{textAlign: 'left'}}>
                While we can’t let you invest in loans yet, feel free to view this page to see loan application details as we receive them to better understand
                how this process will work once fully functional.
            </p>
            <p style={{textAlign: 'left'}}>
                we also invite you to use our financial tracking dashboard to help monitor your spending. 
                Activate it anytime on the <span style={{textDecoration: 'underline', cursor: 'pointer'}} onClick={()=>{navigate("/account")}}>account page</span>.
            </p>
            <h5>Tired of seeing this message?</h5>
            <small>
                Enter your name in the <span style={{textDecoration: 'underline', cursor: 'pointer'}} onClick={()=>{navigate("/account")}}>account page</span> and 
                this message will stop displaying.
            </small>
        </div>
    );
}

// TODO: lender loan performance
const LenderHomePage = () => {
    useLoadedAnalytics("lender_home");
    const [showSignInModal, setShowSignInModal] = useState(false);
    const [showMessageModal, setShowMessageModal] = useState(false);
    const [loanViewFilter, setLoanViewFilter] = useState("all");
    const [isSaving, setIsSaving] = useState(false);

    const [userData, setUserData] = useState<User>(new UserImpl());
    const [loanCriteria, setLoanCriteria] = useState<LenderLoanCriteria[]>([]); //TODO: this is a bandaid fix - the state management is wack
    const [lenderLoans, setLenderLoans] = useState<Loan[]>([]);

    const filterLoans = (loans: Loan[], filter: string) => {
        switch (filter) {
            case 'active':
                return loans.filter(loan => loan.isActive);
            case 'done':
                return loans.filter(loan => loan.isComplete);
            case 'default':
                return loans.filter(loan => loan.isDefault);
            // case 'forSale':
            //     return loans.filter(loan => loan.isForSale);
            case 'all':
            default:
                return loans;
        }
    };

    const filteredLoans = filterLoans(lenderLoans, loanViewFilter);

    // if login occured, check plaid access
    const userLoggedInCB = () => {
        setShowSignInModal(false);
        setUserData(getUserCookieData()!);
    }

    // on load asser login and set userdata if logged in
    useEffect(()=>{
        const user = getUserCookieData()
        if(!user) {
            setShowSignInModal(true);
        } else {
            setShowMessageModal(user.bio.firstName.length > 1 && user.bio.lastName.length > 1 ? false : true);
            setLoanCriteria(user.loanData.loanCriteria);
            setUserData(user);
        }
    }, [])

    const onCriteriaChange = (criteria: LenderLoanCriteria[]) => {
        if(criteria !== userData.loanData.loanCriteria) {
            setUserData({...userData, loanData: {loanCriteria: criteria}});
        }
    }

    const criteriaSaveHandler = async () => {
        setIsSaving(true);
        saveUserData(userData);
        await sleep(100);
        setIsSaving(false);
    }

    // TODO: Enable when marketplace exists
    // const onSellClick = (idx) => {
    //     // eslint-disable-next-line no-restricted-globals
    //     const wantSell = confirm(`Liquidate your share of the loan.\n\n You'll recieve 90% ($${(0.9*(lenderLoans[idx].loanTotalInCents - lenderLoans[idx].loanPaidInCents)/1000).toFixed(2)}) your portion of the remaining principal payments ($${((lenderLoans[idx].loanTotalInCents - lenderLoans[idx].loanPaidInCents)/1000).toFixed(2)}) when someone buys your portion.\n\n Until someone buys your portion, you'll continue to recieve payments.`);
    // }

    return (
        <div className={styles.LendHomeContainer}>
            {showSignInModal ? 
            <SignUpInModal showModal={showSignInModal} processCompleteHook={userLoggedInCB} /> 
            :
            <>
                <MessageModal isShown={showMessageModal} setIsShown={setShowMessageModal} message={<Message/>} />
                {/* view loan marketplace for listings */}
                <MarketPlaceView />
                {/* TODO: Performance Charts (BrushBarChart)?*/}
                {/* show loans with filter - all, active, done, default */}
                <div style={{backgroundColor: 'var(--bg-color-2', border: '1px solid var(--text-color-3', borderRadius: '1rem', margin: '2vh 2vw'}}>
                    <h1>Loans Funded</h1>
                    <SelectPrimary value={loanViewFilter} onChange={(e)=>{setLoanViewFilter(e.target.value)}}>
                        <option value="all">all</option>
                        <option value="active">active</option>
                        <option value="done">done</option>
                        <option value="default">defaulted</option>
                        {/* <option value="forSale">for sale</option> */}
                    </SelectPrimary>
                    { filteredLoans.length > 1 ? <LoanViewCards filteredLoans={filteredLoans}/> : <p>No Loans Here</p>}
                </div>
                {/* editable criteria list */}
                <div style={{backgroundColor: 'var(--bg-color-2)', border: '1px solid var(--text-color-3', borderRadius: '1rem', margin: '2vh 2vw'}}>
                    <CriteriaManagement initialCriteria={loanCriteria} onCriteriaChange={onCriteriaChange} secondaryButton={
                        <ButtonSecondary onClick={criteriaSaveHandler} disabled={isSaving}>{isSaving ? ". . ." : "Save"}</ButtonSecondary>
                    }/>
                </div>
            </>
            }
        </div>
    )
}

export default LenderHomePage;