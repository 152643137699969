import './loading_icon.css'

import React from 'react';

// eslint-disable-next-line no-lone-blocks
{/* https://cssloaders.github.io */}

const LoadingIcon = () => {
    return (
        <div>
            <span className='loader'></span>
        </div>
    );
}

export default LoadingIcon;