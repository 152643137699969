import styles from './carousel.module.css';

import React, { useCallback, useEffect, useRef, useState } from 'react';

interface CarouselProps {
    children: JSX.Element[]
}

const Carousel = ({children}: CarouselProps) => {
    const CARD_AUTO_ROTATION_MS = 8000;
    const [currentIndex, setCurrentIndex] = useState(0);
    const intervalRef = useRef<number | null>(null);
    const startXRef = useRef<number | null>(null);
    const carouselRef = useRef<HTMLDivElement | null>(null); // Ref for the carousel container
    const [isInView, setIsInView] = useState(false); // Track whether the carousel is in view

    const stopAutoRotation = useCallback(() => {
        if (intervalRef.current !== null) {
            clearInterval(intervalRef.current);
        }
    }, []);

    const startAutoRotation = useCallback(() => {
        stopAutoRotation(); // Clear any existing intervals before starting a new one
        if (isInView) {
            intervalRef.current = window.setInterval(() => {
                setCurrentIndex((prevIndex) => (prevIndex + 1) % children.length);
            }, CARD_AUTO_ROTATION_MS);
        }
    }, [children.length, isInView, stopAutoRotation]);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        setIsInView(true);
                    } else {
                        setIsInView(false);
                        stopAutoRotation();
                    }
                });
            },
            { threshold: 0.2 } // Trigger when 20% of the carousel is in view
        );

        if (carouselRef.current) {
            observer.observe(carouselRef.current);
        }

        return () => {
            if (carouselRef.current) {
                observer.unobserve(carouselRef.current);
            }
        };
    }, [stopAutoRotation]);
  
    useEffect(() => {
        if (isInView) {
            startAutoRotation();
        } else {
            stopAutoRotation();
        }

        return () => {
            stopAutoRotation();
        };
    }, [isInView, startAutoRotation, stopAutoRotation]);
  
    const handleDotClick = (index: number) => {
        setCurrentIndex(index-1); // mimnus one as the click also increments the dots
        stopAutoRotation();
        startAutoRotation();
    };
  
    const handleCarouselClick = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % children.length);
        stopAutoRotation();
        startAutoRotation();
    };
  
    const handleTouchStart = (e: React.TouchEvent) => {
        startXRef.current = e.touches[0].clientX;
        document.body.style.overflow = 'hidden';
    };
  
    const handleTouchEnd = (e: React.TouchEvent) => {
        if (startXRef.current !== null) {
            const endX = e.changedTouches[0].clientX;
            const diffX = startXRef.current - endX;

            if (Math.abs(diffX) > 50) {
                if (diffX > 0) {
                    // Swiped left
                    setCurrentIndex((prevIndex) => (prevIndex + 1) % children.length);
                } else {
                    // Swiped right
                    setCurrentIndex((prevIndex) => (prevIndex - 1 + children.length) % children.length);
                }
                stopAutoRotation();
                startAutoRotation();
            }

            startXRef.current = null;
        }
        document.body.style.overflow = '';
    };

    const renderCards = (items: JSX.Element[]) => {
        return (
            <>
                {items.map((item, index: number) => {
                    return (
                        <div key={index} className={`${styles.CarouselItem} ${currentIndex === index ? styles.active : ''}`}>
                            {item}
                        </div>
                    );
                })}
            </>
        );
    };

    return (
        <div 
            ref={carouselRef}
            className={styles.CarouselContainer}
            onMouseEnter={stopAutoRotation} 
            onMouseLeave={startAutoRotation} 
            onClick={handleCarouselClick}
            onTouchStart={handleTouchStart}
            onTouchEnd={handleTouchEnd}
        >
            <div className={styles.Carousel}>
                <div className={styles.CarouselInner} style={{ transform: `translateX(-${currentIndex * 100}%)` }}>
                    {renderCards(children)}
                </div>
                <div className={styles.CarouselDots}>
                    {Array.from({ length: children.length }, (_, i) => i).map((dotIndex) => (
                        <div
                            key={dotIndex}
                            className={`${styles.CarouselDot} ${currentIndex === dotIndex ? styles.Active : ''}`}
                            onClick={() => handleDotClick(dotIndex)}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Carousel;