import styles from './bar_chart.module.css';
import { TransactionData } from "../../../helpers/api";
import { Bar, BarChart, Cell, ResponsiveContainer, XAxis, Tooltip as ChartTooltip,} from "recharts";

const categoryMappingName: { [key: string]: string } = {
    GENERAL_MERCHANDISE: "Shopping",
    GOVERNMENT_AND_NON_PROFIT: "Gov & NonProfit",
    ENTERTAINMENT: "Entertainment",
    FOOD_AND_DRINK: "Food & Drink",
    TRANSPORTATION: "Transport",
    TRAVEL: "Travel",
    LOAN_PAYMENTS: "Loan Payment",
    BANK_FEES: "Bank Fees",
    HOME_IMPROVEMENT: "Home Improvement",
    MEDICAL: "Health",
    GENERAL_SERVICES: "Services",
    TRANSFERS: "Transfers",
    RENT_AND_UTILITIES: "Rent & Utilities",
    PERSONAL_CARE: "Personal Care",
    INCOME: "Income",
    TRANSFER_IN: "Transfer IN",
    TRANSFER_OUT: "Transfer OUT",
};

const ExpenseBarChart = ({data}: {data: TransactionData[]}) => {

    const keys = [
        "Transfers", "Loan Payment", "Bank Fees", "Entertainment", "Food & Drink",
        "Shopping", "Home Improvement", "Health", "Services", "Gov & NonProf",
        "Transport", "Travel", "Rent & Utilities", "Personal Care", "Income", "Transfer IN", "Transfer Out"
    ];

    const CustomTooltip = ({ active, payload, label }: {active?: any, payload?: any, label?: any}) => {
        if (active && payload && payload.length) {
            return (
                <div className={styles.customTooltip}>
                    <p className={styles.label}>{label}</p>
                    <ul className={styles.tooltipList}>
                    {payload.map((entry: any, index: number) => (
                        <li key={`item-${index}`} style={{ color: entry.fill }}>
                            {entry.name}: {entry.value}
                        </li>
                    ))}
                    </ul>
                </div>
            );
        }
        return null;
    };

    const transformedData = data.reduce((acc: any[], transaction) => {
        const transactionDate = new Date(transaction.date).toLocaleDateString("en-US");
        const category = categoryMappingName[transaction.personal_finance_category] || "Other";
        const amount = transaction.amount;
        // Find if the date already exists in the accumulator
        let existingDateEntry = acc.find(entry => entry.Date === transactionDate);
        if (!existingDateEntry) {
            existingDateEntry = { Date: transactionDate };
            acc.push(existingDateEntry);
        }
        // Add the amount to the correct category
        if (existingDateEntry[category]) {
            existingDateEntry[category] += amount;
        } else {
            existingDateEntry[category] = amount;
        }
        return acc;
    }, []);

    return (
        <div className={styles.ExpenseTimeline}>
            <ResponsiveContainer>
                <BarChart
                    width={100}
                    height={100}
                    data={transformedData}
                    margin={{top: 5, right: 30, left: 20, bottom: 5}}
                >
                    <XAxis dataKey="Date" />
                    {keys.map((val, idx) => {
                        return (
                            <Bar
                                key={`bar-${idx}`}
                                dataKey={val}
                                fill={`var(--accent-color-${idx + 1})`}
                                stackId="viewCount"
                                radius={[10, 10, 0, 0]}
                            >
                            {transformedData.map((entry, index) => {
                                const keys = Object.keys(entry);
                                const values = Object.values(entry);
                                const channelNameIndex = keys.findIndex((key) => key === val);
                                const lastBarIndex = values.findLastIndex((value) => value !== 0);
                                if (channelNameIndex === lastBarIndex) {
                                    return <Cell key={`last-cell-${index}`} />;
                                }
                                return <Cell key={`cell-${index}`} radius={0} />;
                            })}
                            </Bar>
                        );
                    })}
                    <ChartTooltip content={<CustomTooltip />} cursor={{fill: 'transparent'}}/>
                </BarChart>
            </ResponsiveContainer>
        </div>
    );
}

export default ExpenseBarChart;