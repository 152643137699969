import styles from './signin_up_modal.module.css';

import React, { useEffect, useState } from 'react';
import { sign_in, sign_up, forgot_password } from '../../helpers/api.ts';
import { initSaveUserDataToCookieOnly } from '../../helpers/user.ts';
import { InputSecondary } from '../input/input.tsx';
import { ButtonCTA } from '../button/button.tsx';
import { ErrorMessageModal } from '../my_modal/my_modal.tsx';
import { generateFingerprint } from '../../helpers/fingerprint.ts';
import { SHA256 } from 'crypto-js';

const enum LogInStates {
    "valid",
    "invalid",
    "awaiting",
}

export const enum SignInOrUp {
    "signUp",
    "signIn",
    "otp"
}

export const SignUpInModal = ({showModal, modalEscapeCB, processCompleteHook, openIn}: {showModal: boolean, modalEscapeCB?: ()=>void, processCompleteHook?: ()=>void, openIn?: SignInOrUp}) => {
    const [showErrMsg, setShowErrMsg] = useState(false);
    const [errMsgTxt, setErrMsgTxt] = useState([""]);

    const [userEmail, setUserEmail] = useState("");
    const [userPassword, setUserPassword] = useState("");
    const [userConfirmPassword, setUserConfirmPassword] = useState("");
    const [referralCode, setRefferalCode] = useState("");
    const [otp, setOtp] = useState("");
    const [signUpInState, setSignUpInState] = useState(LogInStates.invalid);
    const [signInOrUp, setSignInOrUp] = useState<SignInOrUp>(openIn !== undefined ? openIn : SignInOrUp.signIn);
    const [awaitingLogIn, setIsAwaitingLogIn] = useState(false);

    const onUserEmailChange = (e: { target: { value: React.SetStateAction<string>; }; }) => {
        setUserEmail(e.target.value);
    }

    const onUserPasswordChange = (e: { target: { value: React.SetStateAction<string>; }; }) => {
        setUserPassword(e.target.value);
    }

    const onUserConfirmPasswordChange = (e: { target: { value: React.SetStateAction<string>; }; }) => {
        setUserConfirmPassword(e.target.value);
    }

    const onReferralCodeChange = (e: any) => {
        setRefferalCode(e.target.value);
    }

    const onOtpChange = (e: any) => {
        setOtp(e.target.value);
    }

    const onResendCode = async () => {
        try {
            await sign_up(userEmail, userPassword, "", "", null);
            setSignInOrUp(SignInOrUp.otp)
        } catch (error: any) {
            setErrMsgTxt([error.message]);
            setShowErrMsg(true);
        }
    }

    useEffect(()=> {
        const validEmail = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(userEmail).valueOf();
        // validate info for neat effects
        if(signInOrUp === SignInOrUp.signIn && userPassword.length > 0 && validEmail) {
            setSignUpInState(LogInStates.valid);
        } else if(signInOrUp === SignInOrUp.signUp && userConfirmPassword === userPassword && userConfirmPassword.length > 0 && userPassword.length > 0 && validEmail) {
            setSignUpInState(LogInStates.valid);
        } else if(signInOrUp === SignInOrUp.otp && otp.length === 6) {
            setSignUpInState(LogInStates.valid);
        } else {
            setSignUpInState(LogInStates.invalid);
        }
    }, [signInOrUp, userConfirmPassword, userEmail, userPassword, otp])

    const onSubmit = async (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        if(!(signUpInState === LogInStates.valid)) return;
        setIsAwaitingLogIn(true);
        if(signInOrUp === SignInOrUp.signIn) {
            try {
                let resp = await sign_in(userEmail, userPassword);
                initSaveUserDataToCookieOnly(resp);
                if(processCompleteHook) processCompleteHook();
            } catch (error: any) {
                setErrMsgTxt([error.message]);
                setShowErrMsg(true);
            }
        } else if(signInOrUp === SignInOrUp.signUp) {
            try {
                await sign_up(userEmail, userPassword, referralCode, "", null);
                setSignInOrUp(SignInOrUp.otp)
            } catch (error: any) {
                setErrMsgTxt([error.message]);
                setShowErrMsg(true);
            }
        } else if(signInOrUp === SignInOrUp.otp) {
            try {
                let resp = await sign_up(userEmail, userPassword, referralCode, otp, sessionStorage.getItem("utm_source"));
                if (sessionStorage.getItem("utm_source") === 'TikTok') {
                    if (window.ttq) {
                        const fingerprint = await generateFingerprint();
                        const hashedEmail = SHA256(userEmail).toString();
                        const hashedFingerprint = SHA256(fingerprint).toString();
                        window.ttq.identify({
                            "email": hashedEmail,
                            "external_id": hashedFingerprint
                        });
                        window.ttq.track('SubmitForm', {
                            "contents": [
                                {
                                    "content_id": "waitlist_submission",
                                    "content_name": "Waitlist Form"
                                }
                            ]
                        });
                    }
                }
                initSaveUserDataToCookieOnly(resp);
                setSignInOrUp(SignInOrUp.signIn)
                if(processCompleteHook) processCompleteHook()
            } catch (error: any) {
                setErrMsgTxt([error.message]);
                setShowErrMsg(true);
            }
        }
        setIsAwaitingLogIn(false);
    }

    const onForgotPassword = () => {
        const validEmail = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(userEmail).valueOf();
        if(!validEmail) {
            // eslint-disable-next-line no-restricted-globals
            confirm("Please enter the email associated with your account");
        } else {
            forgot_password(userEmail);
            // eslint-disable-next-line no-restricted-globals
            confirm("An email with reset instructions has been sent to your email");
        }
    }

    const onCreateNewAccount = () => {
        signInOrUp === SignInOrUp.signIn ? setSignInOrUp(SignInOrUp.signUp) : signInOrUp === SignInOrUp.signUp ? setSignInOrUp(SignInOrUp.signIn) : setSignInOrUp(SignInOrUp.signUp);
    }

    return (
        <dialog className={styles.SignupModal} open={showModal}>
            <ErrorMessageModal isShown={showErrMsg} setIsShown={setShowErrMsg} messages={errMsgTxt}/>
            <div className={styles.InsideContainer}>
                {modalEscapeCB ? <button className={styles.Close} onClick={modalEscapeCB}>❌</button> : null}
                <h1 className={styles.HeadingText}>
                    Sign {signInOrUp === SignInOrUp.signIn ? "In" : "Up"}
                </h1>
                {/* yuck */}
                <form className={styles.Form} onSubmit={onSubmit}>
                    {signInOrUp === SignInOrUp.otp ?
                    null
                    :
                    <div className={styles.Input}>
                        <InputSecondary type='email' onChange={onUserEmailChange} placeholder='your@email.com'/>
                    </div>
                    }
                    
                    {signInOrUp === SignInOrUp.signIn ? 
                        <div className={styles.Input}>
                            <InputSecondary type='password' onChange={onUserPasswordChange} placeholder='password' minLength={8}/>
                        </div>
                    : signInOrUp === SignInOrUp.signUp ?
                        <div>
                            <div className={styles.Input}>
                                <InputSecondary type='password' onChange={onUserPasswordChange} placeholder='new password' minLength={8}/>
                            </div>
                            <div className={styles.Input}>
                                <InputSecondary type='password' onChange={onUserConfirmPasswordChange} placeholder='confirm password'/>
                            </div>
                            <div className={styles.Input}>
                                <InputSecondary type='text' onChange={onReferralCodeChange} placeholder='referral code?'/>
                            </div>
                            
                        </div>
                        :
                        <div>
                            <p>We sent a one time password to:</p>
                            <h3>{userEmail}</h3>
                            <p>Please enter the code here to verify your email.</p>
                            <div className={styles.Input}>
                                <InputSecondary type='text' onChange={onOtpChange} placeholder='one-time password'/>
                            </div>
                        </div>
                    }
                    <ButtonCTA onClick={onSubmit} disabled={awaitingLogIn}>{awaitingLogIn ? "..." : signInOrUp === SignInOrUp.otp ? "Verify" : "Enter"}</ButtonCTA>
                </form>
                <div className={styles.CreateNewAccount} onClick={onCreateNewAccount}>
                    {signInOrUp === SignInOrUp.signIn ? "Create new account" : signInOrUp === SignInOrUp.signUp ? "Existing Sign In" : "Re-enter Email"}
                </div>
                { signInOrUp === SignInOrUp.otp ?
                    <div className={styles.ForgotPassword} onClick={onResendCode}>
                        resend code?
                    </div>
                :
                    <div className={styles.ForgotPassword} onClick={onForgotPassword}>
                        forgot password?
                    </div>
                }
            </div>
        </dialog>
    );
}
