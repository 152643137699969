import { useNavigate } from 'react-router-dom';
import styles from './waitlist_message.module.css'
import { ButtonCTA } from '../../../../components/button/button';

const WaitlistMessage = () => {
    const navigate = useNavigate();

    const onAccountClick = () => {
        navigate("/account");
    }

    const onFaqClick = () => {
        navigate("/faq?q=referral");
    }

    return (
        <div className={styles.WaitlistMessageContainer}>
            <h2>Thank You for Your Interest</h2>
            <p>We appreciate your application, but as a small startup still in the early stages of development, our peer-to-peer lending service is not yet operational. We are currently gauging market interest and need more users to support the platform's full launch.</p>
            <h3>Your Impact</h3>
            <p>Your application brings us closer to reaching the critical number of users needed to make this service a reality. Your time and effort are highly valued.</p>
            <h3>Invite & Earn</h3>
            <p>When you signed up, you received a referral code. By inviting others to join, you can earn monetary rewards. Learn more on the <span onClick={onFaqClick}>FAQ page</span>.</p>
            <h3>In the Meantime</h3>
            <p>While we can’t offer you a loan yet, we invite you to use our financial tracking dashboard to help monitor your spending. Activate it anytime on the <span onClick={onAccountClick}>account page</span>.</p>
            <div>
                <ButtonCTA onClick={()=>{navigate("/account")}}>Account</ButtonCTA>
            </div>
        </div>
    );
}

export default WaitlistMessage;