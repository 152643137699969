import './reset_password_form.css';

import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { resetPassword } from '../../helpers/api.ts';
import { ButtonCTA } from '../../components/button/button.tsx';

const ResetPasswordForm = () => {

    const navigate = useNavigate();
    const [userPassword, setUserPassword] = useState("");
    const [userConfirmPassword, setUserConfirmPassword] = useState("");
    const [submitValid, setSubmitValid] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [secretData, setSecretData] = useState("");

    const onUserPasswordChange = (e: { target: { value: React.SetStateAction<string>; }; }) => {
        setUserPassword(e.target.value);
    }

    const onUserConfirmPasswordChange = (e: { target: { value: React.SetStateAction<string>; }; }) => {
        setUserConfirmPassword(e.target.value);
    }

    useEffect(()=>{
        if(userPassword === userConfirmPassword && userConfirmPassword.length >= 8) {
            setSubmitValid(true);
        } else {
            setSubmitValid(false);
        }
    }, [userPassword, userConfirmPassword])

    const location = useLocation();

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const secret = params.get("id");
        if(secret) {
            setSecretData(secret);
        }
    }, [location]);

    const onSubmit = async (e: { preventDefault: () => void; }) => {
        setIsSubmitting(true);
        e.preventDefault();
        const wasReset = await resetPassword(secretData, userPassword);
        setIsSubmitting(false);
        if(wasReset) {
            alert("Your password was successfully changed to the new password you provided");
        } else {
            alert("An error occured and prevented your password from being changed, if you believe this is an error, contact support");
        }
        navigate("/");
    }

    return (
        <div className='ResetPasswordContainer'>
            <form onSubmit={onSubmit} className='ResetPasswordFormContainer'>
                <h2 className='ForgotPasswordTitle'>Enter your new password</h2>
                <h6>Password should be at least 8 character long</h6>
                <div className='FormInputsContainer'>
                    <input className='Input' type='password' onChange={onUserPasswordChange} placeholder='new password' minLength={8}/>
                    <input className='Input' type='password' onChange={onUserConfirmPasswordChange} placeholder='confirm password' minLength={8}/>
                    <ButtonCTA disabled={!submitValid || isSubmitting}>{isSubmitting ? "..." : "Save"}</ButtonCTA>
                </div>
            </form>
        </div>
    );
}

export default ResetPasswordForm;