import { useEffect, useRef, useState } from 'react';
import { AccountData, CreditLiability } from '../../../helpers/api';
import { CreditCardAlt, DollarCircleList, MoneyInsert, MoneyStack, Vault, Wallet } from '../icons/icons';
import styles from './overview.module.css';

export interface HighOverviewPropData {
    title: string
    value: string
    trend: "up" | "down" | ""
}

export interface HighOverviewProps {
    backgroundStyle: 1 | 2 | 3 | 4 //1-3 for background style and image
    HighOverviewData: HighOverviewPropData[]
}

const getTypeClass = (type: number) => {
    switch (type) {
        case 1:
            return "income";
        case 2:
            return "expense";
        case 3:
            return "balance";
        case 4:
            return "savings";
        default:
            return "";
    }
};

export const HighOverview = ({backgroundStyle, HighOverviewData}: HighOverviewProps) => {

    const backgroundColoring = (type: number) => {
        switch (type) {
            case 1:
                return "linear-gradient(225deg, rgba(var(--accent-values-2), 0.5) 0%, transparent 30%)"
            case 2:
                return "linear-gradient(225deg, rgba(var(--cta-values), 0.5) 0%, transparent 30%)"
            case 3:
                return "linear-gradient(225deg, rgba(var(--accent-values-3), 0.5) 0%, transparent 30%)"
            case 4:
                return "linear-gradient(225deg, rgba(var(--accent-values-1), 0.5) 0%, transparent 30%)"        
            default:
                return "unset";
        }
    }

    const typeImage = (type: number) => {
        switch (type) {
            case 3:
                return (
                    <div style={{rotate: "40deg", backgroundColor: "var(--accent-color-2)", width: "6vh", height: "6vh", marginBottom: "2vh", borderRadius: "50%", display: "flex"}}>
                        <div style={{width: "80%", height: "80%", margin: "auto"}}>
                            <DollarCircleList fill={'none'} stroke={'var(--bg-color)'} strokeWidth={'1.5'} />
                        </div>
                    </div>
                );
            case 4:
                return (
                    <div style={{rotate: "-30deg", backgroundColor: "var(--cta-color)", width: "6vh", height: "6vh", marginBottom: "2vh", borderRadius: "50%", display: "flex"}}>
                        <div style={{width: "80%", height: "80%", margin: "auto"}}>
                            <DollarCircleList fill={'none'} stroke={'var(--bg-color)'} strokeWidth={'1.5'} />
                        </div>
                    </div>
                );
            case 2:
                return (
                    <div style={{backgroundColor: "var(--accent-color-3)", width: "6vh", height: "6vh", marginBottom: "2vh", borderRadius: "50%", display: "flex"}}>
                        <div style={{width: "80%", height: "80%", margin: "auto"}}>
                            <Vault fill={'none'} stroke={'var(--bg-color)'} strokeWidth={'1.5'} />
                        </div>
                    </div>
                );
            case 1:
                return (
                    <div style={{backgroundColor: "var(--accent-color-1)", width: "6vh", height: "6vh", marginBottom: "2vh", borderRadius: "50%", display: "flex"}}>
                        <div style={{width: "80%", height: "80%", margin: "auto"}}>
                            <Wallet fill={'none'} stroke={'var(--bg-color)'} strokeWidth={'1.5'} />
                        </div>
                    </div>
                );
            default:
                return (<></>);
        }
    }

    const trendImage = (type: string) => {
        switch (type) {
            case "up":
                return (
                    <div style={{width: "6vh", height: "6vh", rotate: "0deg"}}>
                        <MoneyInsert fill={'none'} stroke={'var(--pos-color'} strokeWidth={'1.5'} />
                    </div>
                );
            case "down":
                return (
                    <div style={{width: "6vh", height: "6vh", rotate: "180deg"}}>
                        <MoneyInsert fill={'none'} stroke={'var(--neg-color'} strokeWidth={'1.5'} />
                    </div>
                );
            default:
                return (
                    <div style={{width: "6vh", height: "6vh"}}>
                        <MoneyStack fill={'none'} stroke={'var(--text-color-3'} strokeWidth={'1.5'}/>
                    </div>
                );
        }
    }

    return (
        <div className={`${styles.HighOverviewContainer} ${styles[getTypeClass(backgroundStyle)]}`} style={{background: backgroundColoring(backgroundStyle)}}>
            <div className={styles.HighOverviewContainerDataContainer}>
                {HighOverviewData.map((data, idx)=>{
                    return (
                        <div key={idx}>
                            {typeImage((2 * backgroundStyle) + idx - 1)}
                            <div>
                                <h3>{data.title}</h3>
                                <h1>{data.value}</h1>
                            </div>
                            {trendImage(data.trend)}
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

export const LiabilitiesOverview = ({ accounts }: { accounts: AccountData[] }) => {
    const creditAccounts = accounts.filter(
        account => account.type === 'credit' && account.liabilities
    );

    return (
        <div className={styles.LiabilitiesOverviewWrapper}>
            <div className={styles.LiabilitiesOverviewContainer}>
                {creditAccounts.map((account, idx) => {
                    const liability = account.liabilities.credit;

                    return (
                        <div key={idx} className={styles.LiabilitiesCardContainer}>
                            <div className={styles.CreditCardSVGContainer}>
                                <CreditCardAlt fill={'none'} stroke={`rgba(var(--accent-values-1), 0.05)`} strokeWidth={'1.5'}/>
                            </div>
                            <h1>{account.name}</h1>
                            <h2>
                                <span>Balance: </span>{account.current !== undefined ? `$${account.current.toFixed(2)}` : 'N/A'}
                            </h2>
                            {liability?.map((card, idy) => {
                                return (
                                    <div key={idx + idy + 1} className={styles.CardLiability}>
                                        <div className={styles.ColumnLeft}>
                                            <span>On: </span>
                                            <h4 key={`dueDate-${idy}`}>
                                                {card.next_payment_due_date ? card.next_payment_due_date.slice(5) : 'N/A'}
                                            </h4>
                                        </div>
                                        <div className={styles.ColumnRight}>
                                            <span>Min. Due: </span>
                                            <h3 key={`minPayment-${idy}`}>
                                                {card.minimum_payment_amount !== undefined ? `$${card.minimum_payment_amount.toFixed(2)}` : 'N/A'}
                                            </h3>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};