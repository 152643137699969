import './faq.css';
import {ReactComponent as ArrowIcon} from './arrow_icon.svg';
import React, { useState } from 'react';
import { buttonClickInteraction } from '../../helpers/interactions.ts';
import { useLoadedAnalytics } from '../../helpers/analytics.tsx';

import { getQueryParams } from '../../helpers/page.ts';

class InfoSection {
    header: string;
    body: string;
    constructor(header: string, body: string) {
        this.header = header;
        this.body = body;
    }
}

const qaFactory = (q: string, a: InfoSection[]) => {
    return {
        q, a
    };
}

const qas = [
    qaFactory(`What is Crowdcash?`,
        [
            new InfoSection("", `Crowdcash is a peer to peer lending platform. Peer-to-Peer (P2P) lending is a method of debt financing 
                that enables individuals to borrow and lend money without the involvement of traditional financial institutions such as banks.
                In P2P lending, borrowers are connected directly with investors through online platforms, cutting out intermediaries.`)
        ]
    ),
    qaFactory(`What is the referral or affiliate program?`, 
        [
            new InfoSection("", `When you register, you will receive a unique referral code. If a new user registers using your code, both the lender and borrower can 
                earn referral bonuses.`),
            new InfoSection("", `Referral bonuses accumulate with each successful referral. For example, referring 4 people will earn you 4 separate referral bonuses.`),
            new InfoSection("", `Pre-registration referrals (before CrowdCash's official launch) never expire and remain valid indefinitely. Referrals made after the 
                launch will be valid for 60 days. The bonus must be activated within this period, or it will be forfeited.`),
            new InfoSection("What are the referral bonuses?", `Referral bonuses are monetary rewards given to the individual whose code was used during sign-up. Payout 
                terms differ for lenders and borrowers.`),
            new InfoSection("Referral bonus and terms for borrowers", ""),
            new InfoSection("", `If a borrower signs up using a referral code before the official launch, the referrer receives 50% of the origination fee on the 
                borrower's first loan. After the launch, this bonus is reduced to 33%.`),
            new InfoSection("", `For example: If a borrower takes out a $5,000 loan with a 5% origination fee, the referrer would receive $125 as a referral bonus.`),
            new InfoSection("", `The referral bonus is paid out only after the borrower successfully makes their first month's payment. If the borrower misses this 
                payment, the referral bonus is forfeited.`),
            new InfoSection("Referral bonus and terms for lenders", ""),
            new InfoSection("", `If a lender signs up using a referral code before the official launch, the referrer receives 50% of the origination fee on the lender's 
                first funded loan. After the launch, this bonus is reduced to 33%.`),
            new InfoSection("", `For example: If a lender funds a portion or the entirety of a $7,500 loan with a 4% origination fee, the referrer would receive $150 as 
                a referral bonus. If multiple lenders fund the same loan, only one referrer will receive the bonus, chosen at random.`),
            new InfoSection("", `This referral bonus is paid out only after the borrower successfully makes their first month's payment. If the borrower misses this 
                payment, the referral bonus is forfeited.`),
            new InfoSection("Terms", `please see the Terms of Use page (linked in the page's footer) for terms regarding the referral program.`),
        ]
    ),
    qaFactory(`How does Crowdcash assess borrower creditworthiness?`, 
        [
            new InfoSection("", `Borrower creditworthiness is rated using several sources. Credit scores from Experian, Equifax, or TransUnion, Income verification 
                such as pay stubs, tax returns, or bank statements, Employment History, Loan Purpose, Credit History, Bank Account Information and/or any Collateral 
                or Guarantors.`)
        ]
    ),
    qaFactory(`What types of loans are available on Crowdcash?`, 
        [
            new InfoSection("", `Short-Term and Long-Term Loans, Personal Loans, Debt Consolidation Loans, Home Improvement Loans, Auto Loans, Medical Loans, 
                Education Loans, Vacation Loans, and Business Loans.`)
        ]
    ),
    qaFactory(`What are the interest rates offered to borrowers, and how are they determined?`, 
        [
            new InfoSection("", `Interest rates are very competitive and are the same as, or better than rates offered by banks. Rates are determined by Risk-Based 
                Pricing, such as credit score, loan term, loan amount, loan purpose and market conditions.`)
        ]
    ),
    qaFactory(`What fees does Crowdcash charge for borrowers and investors?`, 
        [
            new InfoSection("", `Borrowers are charged an origination fee as a percentage of the loan, late fees for missing payments, and service fees for payment 
                processing, customer support, and account management. Investors are charged a service fee for administrative tasks associated with managing the loan, 
                such as loan servicing, collections, and account maintenance, which may be deducted from the interest earned on the investments or charged separately.`)
        ]
    ),
    qaFactory(`What is the minimum and maximum loan amount that can be borrowed or invested?`, 
        [
            new InfoSection("", `The amount is highly variable and determined by the amount of capital investors on the platform have available to loan. This is the 
                platform's utilization and can be found on the home page.`)
        ]
    ),
    qaFactory(`How are loan repayments handled, and what happens in case of default?`, 
        [
            new InfoSection("", `Loan repayments are handled via scheduled and automated payments through Stripe. Late payments face a penalty fee specified by the 
                loan agreement. Defaults are addressed by various inforts which include: collection efforts, law suits, asset seizure/garnishment of wages 
                (if applicable), and finally charge-offs.`)
        ]
    ),
    qaFactory(`What level of transparency is provided regarding borrower profiles, loan terms, and historical performance?`, 
        [
            new InfoSection("", `Detailed borrower profiles that include information about the borrower's credit score, employment status, income, loan purpose, 
                loan amount, and other relevant details. Loan requests along with relevant loan terms, such as interest rate, loan term, loan purpose, 
                loan amount, and repayment schedule. Loan listings are accessible to investors, allowing them to review and analyze available loan 
                opportunities before making investment decisions. Risk assessments are typically based on factors such as credit score, income, 
                employment history, debt-to-income ratio, and loan purpose. Risk grades help investors gauge the level of risk associated with 
                individual loans and make informed investment decisions. Historical performance data is avilable and includes statistics on 
                loan default rates, repayment rates, investor returns, and other relevant metrics. Performance of loan investments can be viewed in 
                real-time. Investors can track loan repayments, view payment histories, and receive updates on any late payments or defaults.`)
        ]
    ),
    qaFactory(`Is there a secondary market where investors can sell their loan investments if needed?`, 
        [
            new InfoSection("", `Investors who wish to sell their loan investments can list them for sale on the platform's internal secondary market. They can specify 
                the desired sale price, loan amount, interest rate, remaining term, and other relevant details. Once listed, the loan investments become available for 
                purchase by other investors on the platform. Investors browsing the secondary market can review available listings and decide whether to purchase 
                loans based on their investment criteria and risk preferences.  Once a buyer purchases a loan investment from the secondary market, the ownership of 
                the loan is transferred from the seller to the buyer. The buyer becomes entitled to receive future loan repayments, including interest and principal, 
                according to the terms of the original loan agreement.`)
        ]
    ),
    qaFactory(`What are Crowdcash's procedures for resolving disputes between borrowers and investors?`, 
        [
            new InfoSection("", `There are customer support channels, such as email, live chat, and online helpdesk systems, where borrowers and investors can reach 
                out for assistance with inquiries, complaints, or disputes.In cases where disputes cannot be resolved through informal negotiations, mediation or 
                arbitration may be used as alternative dispute resolution methods.`)
        ]
    ),
    qaFactory(`What measures does Crowdcash have in place to protect investors from fraud or default?`, 
        [
            new InfoSection("", `Thorough borrower screening and due diligence to assess the creditworthiness and financial stability of potential borrowers. This may 
                include verifying borrowers' identities, income, employment history, credit scores, and other relevant financial information. By screening borrowers 
                rigorously, Crowdcash aim to reduce the likelihood of defaults and fraudulent activities.`)
        ]
    ),
    // qaFactory(`What is the track record of Crowdcash in terms of loan performance and investor returns?`, 
    //             ``),
    qaFactory(`Is Crowdcash regulated by any financial authorities, and what regulatory compliance measures are in place?`, 
        [
            new InfoSection("", `Crowdcash adheres to applicable laws and regulations governing consumer protection, financial services, investor disclosures, and 
                anti-fraud measures.`)
        ]
    ),
    qaFactory(`What are the eligibility criteria for borrowers and investors?`, 
        [
            new InfoSection("", `Borrowers must be of legal age to apply for loans, demonstrate sufficient income to repay their loan, rovide proof of employment or 
                stable income sources, be residents of where Crowdcash operates (In the USA), loans must not be for illegal activities or speculative investments. 
                Investors must be of legal age to participate in loaning activities, meet the minimum investment amount of $100, be residents where Crowdcash is 
                operating, and understand the inherent risk of investment.`)
        ]
    ),
    qaFactory(`How long does it typically take for a loan application to be processed and funded?`, 
        [
            new InfoSection("", `After a short application and verification, Crowdcash's advanced algorithms allows for loans to be processed and funded immediately 
                if funds are available`)
        ]
    ),
    qaFactory(`What customer support options are available for borrowers and investors?`, 
        [
            new InfoSection("", `Crowdcash has email, live chat, and online helpdesk systems to help answer any questions that investors and/or borrowers may have, 
                including this FAQ section.`)
        ]
    ),
    qaFactory(`What happens if Crowdcash itself faces financial difficulties or goes out of business?`, 
        [
            new InfoSection("", `Borrowers who have active loans may still be required to repay their loans according to the terms of the loan agreements. The loans 
                may be transferred to a third-party loan servicer or administrator appointed to manage the loan portfolio and collect repayments from borrowers. 
                Investor-funded loans to another P2P lending platform or financial institution willing to assume servicing responsibilities. Investors would continue 
                to receive loan repayments and recover their investments through the new servicer.`)
        ]
    ),
    qaFactory(`Are there any specific risks associated with investing through Crowdcash that users should be aware of?`, 
        [
            new InfoSection("", `Investments are subject to credit risk, meaning that borrowers may default on their loan obligations, leading to partial or total loss 
                of invested capital. Factors such as borrower creditworthiness, economic conditions, and loan underwriting standards can affect the likelihood of default. 
                Investing in P2P loans involves concentration risk if investors allocate a significant portion of their investment portfolio to a small number of loans 
                or borrowers. Lack of diversification increases the impact of individual loan defaults on the overall investment portfolio. investments may be sensitive 
                to changes in interest rates, inflation, and monetary policy. Rising interest rates can decrease the present value of future loan repayments, reducing 
                investor returns, while falling interest rates can increase prepayment risk and reinvestment risk. 2P loans are typically illiquid investments with fixed 
                maturities, meaning that investors may not be able to sell their loan investments easily or at fair market prices before the loans reach maturity.`)
        ]
    ),
    qaFactory(`Are there any specific risks associated with borrowing through Crowdcash that users should be aware of?`, 
        [
            new InfoSection("", `Borrowers may face challenges in repaying their loans if they experience financial difficulties, job loss, or unexpected expenses. 
                Defaulting on a P2P loan can damage the borrower's credit score and result in collection efforts, legal action, or other adverse consequences. 
                Borrowers may be exposed to interest rate risk if they have variable-rate loans or if interest rates increase over time, leading to higher 
                borrowing costs and potential affordability challenges. Borrowers may incur origination fees, late payment fees, prepayment penalties, 
                or other charges associated with P2P loans. These fees can increase the cost of borrowing and affect the overall affordability of the loan.`)
        ]
    ),
    qaFactory(`What are the tax implications for investors earning returns through Crowdcash?`, 
        [
            new InfoSection("", `The returns earned by investors through Crowdcash are typically classified as interest income for tax purposes. 
                Interest income is generally taxable and must be reported on the investor's tax return as part of their taxable income in a 1099-INT form.`)
        ]
    ),
    // qaFactory(`Can Crowdcash provide any helpful or otherwise educational resources or guidance for users unfamiliar with P2P lending?`, 
    //             ``),

    qaFactory(`What happens if someone doesn't pay back (defaults on) their loan?`, 
        [
            new InfoSection("", `The same as if someone doesn't pay back their bank loan; They're sent to collections, their credit score is reduced, etc.
                Ultimately, as the lender, you lose out on the payment - just like losing out on an investment.`)
        ]
    ),
    qaFactory(`How do I manage risk?`, 
        [
            new InfoSection("", `Crowdcash lets you choose borrowers anoynmously based on credit scores, income, employment and loan purpose.`)
        ]
    ),
    qaFactory(`How "good" are interest rates?`, 
        [
            new InfoSection("", `Borrowers are guaranteed rates at or under any bank or credit card because Crowdcash boasts: Marketplace Competition, Efficient 
                Technology, Risk-Based Pricing, Diverse Funding Sources, Flexible Terms, and Reduced Regulatory Burden.`)
        ]
    ),
]

const QA = ({q, a}) => {
    const [isVisible, setIsVisible] = useState(false);

    const handleClick = () => {
        setIsVisible(!isVisible);
        let data = {
            buttonName: "faq button select",
            choice: q
        };
        buttonClickInteraction(data);
    }

    return (
        <div className='QAContainer'>
            <div className='QContainer' onClick={handleClick}>
                <p className='QText'>{q}</p>
                <ArrowIcon className={`QAQArrowIconSVG ${isVisible ? "flipped" : ""}`} />
            </div>
            {isVisible ? <div className='AContainer'>
                {a.map((a: InfoSection, idx: number) => {
                    return (
                        <div key={idx}>
                            {a.header ? <h4>{a.header}</h4> : null}
                            <p>{a.body}</p>
                        </div>
                    );
                })}
            </div> : null}
            
        </div>
    )
}

const FAQ = () => {
    // useScrollAnalytics("faq");
    useLoadedAnalytics("faq");

    const [filterValue, setFilterValue] = useState(getQueryParams().get('q') || "");

    const handleChange = (e) => {
        setFilterValue(e.target.value);
    }

    const filteredQAs = qas.filter(qa => {
        const searchWords = filterValue.toLowerCase().split(" ");
        return searchWords.some(word => qa.q.toLowerCase().includes(word));
    }).sort((qaA, qaB) => {
        const searchWords = filterValue.toLowerCase().split(" ");
        const matchesA = searchWords.filter(word => qaA.q.toLowerCase().includes(word)).length;
        const matchesB = searchWords.filter(word => qaB.q.toLowerCase().includes(word)).length;
        return matchesB - matchesA;
    });


    return (
        <div className='FaqContainer'>
            <input className='FAQInputOption' type="text" placeholder={"Search"} onChange={handleChange} value={filterValue}/>
            {filteredQAs.map((qa, index) => {
                return <QA key={index} q={qa.q} a={qa.a} />
            })}
        </div>
    );
}

export default FAQ;