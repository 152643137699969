import React from "react"
import styles from "./select.module.css"

export const SelectCTA = (props: JSX.IntrinsicElements["select"]) => {
    const { children, ...rest } = props;
    return (
        <select className={`${styles.Select} ${styles.CTA} ${styles.CTAHover}`} {...rest}>{children}</select>
    );
}

export const SelectPrimary = (props: JSX.IntrinsicElements["select"]) => {
    const { children, ...rest } = props;
    return (
        <select className={`${styles.Select} ${styles.Primary} ${styles.PrimaryHover}`} {...rest}>{children}</select>
    );
}

export const SelectSecondary = (props: JSX.IntrinsicElements["select"]) => {
    const { children, ...rest } = props;
    return (
        <select className={`${styles.Select} ${styles.Secondary} ${styles.SecondaryHover}`} {...rest}>{children}</select>
    );
}
