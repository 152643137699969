import styles from './how_it_works.module.css';

import React, { useState, useRef, useEffect, useCallback, forwardRef, Ref, RefObject, MutableRefObject } from 'react';

const borrowerCards = [
    {
        title: 'Application',
        content: 'The borrower provides personal information, financial details, loan amount requested, purpose of the loan, and other relevant information.',
        imagePath: './assets/hiw_borrower/application_img_1.webp'
    },
    { 
        title: 'Assessment',
        content: 'Crowdcash assesses creditworthiness based on factors such as credit score, income, employment history, debt-to-income ratio, and loan purpose.',
        imagePath: './assets/hiw_borrower/assessment_img_1.webp'
    },
    { 
        title: 'Listing',
        content: `Once approved, the loan is listed on Crowdcash's marketplace where lenders can review and fund the loan.`,
        imagePath: './assets/hiw_borrower/listing_img_1.webp'
    },
    { 
        title: 'Funding',
        content: 'Lenders commit to funding a portion of the loan amount, and once the total loan amount is fully funded, the loan is originated and disbursed to the borrower.',
        imagePath: './assets/hiw_borrower/funding_img_1.webp'
    },
    { 
        title: 'Disbursement',
        content: 'The borrower receives the loan funds and can use them for the intended purpose, such as debt consolidation, home improvement, business financing, personal expenses, etc.',
        imagePath: './assets/hiw_borrower/disbursement_img_1.webp'
    },
    { 
        title: 'Repayment',
        content: 'The borrower makes regular loan repayments according to the terms of the loan agreement, including the repayment schedule, interest rate, and loan term.',
        imagePath: './assets/hiw_borrower/repayment_img_1.webp'
    },
    { 
        title: 'Servicing',
        content: 'CrowdCash services the loan throughout its duration, providing customer support, loan management, payment processing, and collections efforts as needed.',
        imagePath: './assets/hiw_borrower/servicing_img_1.webp'
    },
    { 
        title: 'Completion',
        content: 'Once the borrower completes all scheduled loan repayments, including interest and principal, the loan is considered fully repaid.',
        imagePath: './assets/hiw_borrower/completion_img_1.webp'
    }
];

const lenderCards = [
    { 
        title: 'Sign Up',
        content: 'The lender sets lending criteria and links their bank account which will fund loans on the platform.',
        imagePath: './assets/hiw_lender/transfer_img_1.webp'
    },
    { 
        title: 'Selection',
        content: `The lender may browse or have an automation select listings on the marketplace according to loan amount, interest rate, loan term, borrower profile, loan purpose, and risk assessment.`,
        imagePath: './assets/hiw_lender/selection_img_1.webp'
    },
    { 
        title: 'Diversification',
        content: 'A diversification strategy may be used to spread investment across multiple loans to minimize risk based on their risk tolerance, investment objectives, and preferences, considering factors such as credit risk, loan term, and interest rate.',
        imagePath: './assets/hiw_lender/diversification_img_1.webp'
    },
    { 
        title: 'Funding',
        content: 'The lender selects specific loans they wish to fund and commits to investing a portion of the loan amount. Once the total loan amount is fully funded the loan is originated and disbursed to the borrower.',
        imagePath: './assets/hiw_lender/funding_img_1.webp'
    },
    { 
        title: 'Servicing',
        content: 'Lenders can access their account dashboard to track loan performance, view repayment schedules, and receive updates on investment activities.',
        imagePath: './assets/hiw_lender/servicing_img_1.webp'
    },
    { 
        title: 'Repayments',
        content: 'Loan repayments are distributed to lenders based on their proportional investment in the loan, net of any fees or charges deducted.',
        imagePath: './assets/hiw_lender/repayments_img_1.webp'
    },
    { 
        title: 'Reinvestment',
        content: 'As loan repayments are received, reinvesting allows lenders to compound returns by continuously deploying capital into new loan investments.',
        imagePath: './assets/hiw_lender/reinvestments_img_1.webp'
    },
    { 
        title: 'Withdrawal',
        content: 'As loan repayments are received, withdrawal provides liquidity and flexibility to access investment returns.',
        imagePath: './assets/hiw_lender/withdrawal_img_1.webp'
    },
    { 
        title: 'Management',
        content: 'Lenders may monitor the performance assessing factors such as repayment rates, defaults, and overall portfolio diversification.',
        imagePath: './assets/hiw_lender/management_img_1.webp'
    },
];

const CardCarousel = ({ name, cards }) => {
    const CARD_AUTO_ROTATION_MS = 8000;
    const [currentIndex, setCurrentIndex] = useState(0);
    const intervalRef = useRef<number | null>(null);
    const startXRef = useRef<number | null>(null);
    const carouselRef = useRef<HTMLDivElement | null>(null); // Ref for the carousel container
    const [isInView, setIsInView] = useState(false); // Track whether the carousel is in view

    const stopAutoRotation = useCallback(() => {
        if (intervalRef.current !== null) {
            clearInterval(intervalRef.current);
        }
    }, []);

    const startAutoRotation = useCallback(() => {
        stopAutoRotation(); // Clear any existing intervals before starting a new one
        if (isInView) {
            intervalRef.current = window.setInterval(() => {
                setCurrentIndex((prevIndex) => (prevIndex + 1) % cards.length);
            }, CARD_AUTO_ROTATION_MS);
        }
    }, [cards.length, isInView, stopAutoRotation]);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        setIsInView(true);
                    } else {
                        setIsInView(false);
                        stopAutoRotation();
                    }
                });
            },
            { threshold: 0.2 } // Trigger when 20% of the carousel is in view
        );

        if (carouselRef.current) {
            observer.observe(carouselRef.current);
        }

        return () => {
            if (carouselRef.current) {
                observer.unobserve(carouselRef.current);
            }
        };
    }, [stopAutoRotation]);
  
    useEffect(() => {
        if (isInView) {
            startAutoRotation();
        } else {
            stopAutoRotation();
        }

        return () => {
            stopAutoRotation();
        };
    }, [isInView, startAutoRotation, stopAutoRotation]);
  
    const handleDotClick = (index: number) => {
        setCurrentIndex(index-1); // mimnus one as the click also increments the dots
        stopAutoRotation();
        startAutoRotation();
    };
  
    const handleCarouselClick = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % cards.length);
        stopAutoRotation();
        startAutoRotation();
    };
  
    const handleTouchStart = (e: React.TouchEvent) => {
        startXRef.current = e.touches[0].clientX;
        document.body.style.overflow = 'hidden';
    };
  
    const handleTouchEnd = (e: React.TouchEvent) => {
        if (startXRef.current !== null) {
            const endX = e.changedTouches[0].clientX;
            const diffX = startXRef.current - endX;

            if (Math.abs(diffX) > 50) {
                if (diffX > 0) {
                    // Swiped left
                    setCurrentIndex((prevIndex) => (prevIndex + 1) % cards.length);
                } else {
                    // Swiped right
                    setCurrentIndex((prevIndex) => (prevIndex - 1 + cards.length) % cards.length);
                }
                stopAutoRotation();
                startAutoRotation();
            }

            startXRef.current = null;
        }
        document.body.style.overflow = '';
    };
  
    return (
        <div
        ref={carouselRef}
        className={styles.CarouselContainer}
        onMouseEnter={stopAutoRotation}
        onMouseLeave={startAutoRotation}
        onClick={handleCarouselClick}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
        >
            <h2>{name}</h2>
            <div className={styles.carousel}>
                <div
                    className={styles.carouselinner}
                    style={{ transform: `translateX(-${currentIndex * 100}%)` }}
                >
                    {cards.map((card: any, idx: number) => (
                        <div key={`card-${idx}`} className={`${styles.carouselitem} ${currentIndex === idx ? 'active' : ''}`}>
                            <h3>{idx + 1}. {card.title} </h3>
                            <div className={styles.cardcontent}>
                                <img src={card.imagePath} alt="" />
                                <p>{card.content}</p>
                            </div>
                        </div>
                    ))}
                </div>
                <div className={styles.carouseldots}>
                    {cards.map((_: any, dotIndex: number) => (
                        <div key={dotIndex} 
                            className={`${styles.carouseldot} ${currentIndex === dotIndex ? styles.active : ''}`} 
                            onClick={() => handleDotClick(dotIndex)}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

const HowItWorks = forwardRef<HTMLDivElement>((props, ref: Ref<HTMLDivElement>) => {
    const divRef = useRef<HTMLDivElement | null>(null); // Fallback ref
    const [headingVisible, setHeadingVisible] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        setHeadingVisible(true);
                    } else if (!entry.isIntersecting) {
                        setHeadingVisible(false);
                    }
                });
            },
            { threshold: 0.2 }
        );

        const targetRef = (ref as RefObject<HTMLDivElement>)?.current || divRef.current;

        if (targetRef) observer.observe(targetRef);

        return () => {
            if (targetRef) observer.unobserve(targetRef);
        };
    }, [ref]);

    return (
        <div
            ref={(node) => {
                if (typeof ref === 'function') {
                    ref(node); // Handle callback ref
                } else if (ref && 'current' in ref) {
                    (ref as MutableRefObject<HTMLDivElement | null>).current = node; // Handle object ref
                }
                divRef.current = node; // Fallback ref
            }}
            className={`${styles.Container} ${headingVisible ? styles.fadeIn : styles.fadeOut}`}
        >
            <h1>How It Works</h1>
            <CardCarousel name={"For Borrowers"} cards={borrowerCards}/>
            <CardCarousel name={"For Lenders"} cards={lenderCards}/>
        </div>
    );
});

export default HowItWorks;