import { TransactionData } from '../../../helpers/api';
import { BankFees, DollarCircleList, Entertainment, FoodAndDrink, GovNonProfit, HomeImprovement, LoanPayment, Medical, PersonalCare, RentUtilities, Services, Shopping, Transportation, Travel, WalletMinus, WalletPlus } from '../icons/icons.tsx'
import styles from './transaction_table.module.css';

const TransactionTable = ({data}: {data: TransactionData[]}) => {

    const reconcileLogo = (logo_url: string | null, category: string | null): JSX.Element  => {
        if(logo_url) return <img src={logo_url} alt='transaction logo'/>
        switch (category) {
            case "LOAN_PAYMENTS":
                return <div style={{backgroundColor: 'var(--accent-color-2)', borderRadius: "50%"}}>
                            <LoanPayment fill={'transparent'} stroke={'var(--bg-color)'} strokeWidth={'2'} />
                        </div>
            case "BANK_FEES":
                return  <div style={{backgroundColor: 'var(--accent-color-3)', borderRadius: "50%"}}>
                            <BankFees fill={'transparent'} stroke={'var(--bg-color)'} strokeWidth={'2'} />
                        </div>
            case "ENTERTAINMENT":
                return  <div style={{backgroundColor: 'var(--accent-color-4)', borderRadius: "50%"}}>
                            <Entertainment fill={'transparent'} stroke={'var(--bg-color)'} strokeWidth={'2'} />
                        </div>
            case "FOOD_AND_DRINK":
                return  <div style={{backgroundColor: 'var(--accent-color-5)', borderRadius: "50%"}}>
                            <FoodAndDrink fill={'transparent'} stroke={'var(--bg-color)'} strokeWidth={'2'} />
                        </div>
            case "GENERAL_MERCHANDISE":
                return  <div style={{backgroundColor: 'var(--accent-color-6)', borderRadius: "50%"}}>
                            <Shopping fill={'transparent'} stroke={'var(--bg-color)'} strokeWidth={'2'} />
                        </div>
            case "HOME_IMPROVEMENT":
                return  <div style={{backgroundColor: 'var(--accent-color-7)', borderRadius: "50%"}}>
                            <HomeImprovement fill={'transparent'} stroke={'var(--bg-color)'} strokeWidth={'2'} />
                        </div>
            case "MEDICAL":
                return  <div style={{backgroundColor: 'var(--accent-color-8)', borderRadius: "50%"}}>
                            <Medical fill={'transparent'} stroke={'var(--bg-color)'} strokeWidth={'2'} />
                        </div>
            case "PERSONAL_CARE":
                return  <div style={{backgroundColor: 'var(--accent-color-9)', borderRadius: "50%"}}>
                            <PersonalCare fill={'transparent'} stroke={'var(--bg-color)'} strokeWidth={'2'} />
                        </div>
            case "GENERAL_SERVICES":
                return  <div style={{backgroundColor: 'var(--accent-color-10)', borderRadius: "50%"}}>
                            <Services fill={'transparent'} stroke={'var(--bg-color)'} strokeWidth={'2'} />
                        </div>
            case "GOVERNMENT_AND_NON_PROFIT":
                return  <div style={{backgroundColor: 'var(--accent-color-11)', borderRadius: "50%"}}>
                            <GovNonProfit fill={'transparent'} stroke={'var(--bg-color)'} strokeWidth={'2'} />
                        </div>
            case "TRANSPORTATION":
                return  <div style={{backgroundColor: 'var(--accent-color-12)', borderRadius: "50%"}}>
                            <Transportation fill={'transparent'} stroke={'var(--bg-color)'} strokeWidth={'2'} />
                        </div>
            case "TRAVEL":
                return  <div style={{backgroundColor: 'var(--accent-color-13)', borderRadius: "50%"}}>
                            <Travel fill={'transparent'} stroke={'var(--bg-color'} strokeWidth={'2'} />
                        </div>
            case "RENT_AND_UTILITIES":
                return  <div style={{backgroundColor: 'var(--accent-color-14)', borderRadius: "50%"}}>
                            <RentUtilities fill={'transparent'} stroke={'var(--bg-color)'} strokeWidth={'2'} />
                        </div>
            case "INCOME":
                return <div style={{backgroundColor: 'var(--accent-color-15)', borderRadius: "50%"}}>
                            <DollarCircleList fill={'transparent'} stroke={'var(--bg-color)'} strokeWidth={'2'} />
                        </div>

            case "TRANSFER_IN":
                return <div style={{backgroundColor: 'var(--accent-color-16)', borderRadius: "50%"}}>
                            <WalletPlus fill={'transparent'} stroke={'var(--bg-color)'} strokeWidth={'2'} />
                        </div>
            case "TRANSFER_OUT":
                return <div style={{backgroundColor: 'var(--accent-color-17)', borderRadius: "50%"}}>
                            <WalletMinus fill={'transparent'} stroke={'var(--bg-color)'} strokeWidth={'2'} />
                        </div>
            default:
                return <></>;
        }
    }

    return (
        <div className={styles.TransactionTableContainer}>
            <h2>Transactions</h2>
            <div>

            </div>
            <div className={styles.TransactionTableTransactions}>
                {data.map((val, idx) => {
                    return (
                        <div key={idx} className={styles.TransactionTableTransactionContainer}>
                            <div className={styles.TransactionLogo}>
                                {reconcileLogo(val.logo_url, val.personal_finance_category)}
                            </div>
                            <div className={styles.TransactionData}>
                                <h3>
                                    {val.merchant_name}
                                </h3>
                                <p>
                                    {val.city || val.state ? [val.city, val.state].filter(Boolean).join(', ') : "\u00A0"}
                                </p>
                                <p>
                                    {val.date}
                                </p>
                            </div>
                            <div className={styles.TransactionData} style={{textAlign: "right", paddingRight: "2vw"}}>
                                <h4>
                                    ${val.amount}
                                </h4>
                                <p>
                                    {val.pending ? "Pending" : "\u00A0"}
                                </p>
                                <p>
                                    {val.account_name}
                                </p>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

export default TransactionTable;