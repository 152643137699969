import CryptoJS from 'crypto-js';

const storageEstimate = async (): Promise<StorageEstimate> => {
    let estimate: StorageEstimate = {quota: 0, usage: 0};
    try {
        estimate = await navigator.storage.estimate();
    } catch (error) {
        // likely occurs in http contexts, or is unavilable
    }
    return estimate;
}

export async function getBrowserFingerprint(): Promise<string> {
    const canvas = document.createElement('canvas');
    const gl = canvas.getContext('webgl') as WebGLRenderingContext | null || canvas.getContext('experimental-webgl') as WebGLRenderingContext | null;

    let vendor = 'unknown';
    let renderer = 'unknown';
    let vertexShaderPrecision = 'unknown';
    let fragmentShaderPrecision = 'unknown';
  
    if (gl) {
        try {
            const debugInfo = gl.getExtension('WEBGL_debug_renderer_info');
            if (debugInfo) {
                vendor = gl.getParameter(debugInfo.UNMASKED_VENDOR_WEBGL) || 'unknown';
                renderer = gl.getParameter(debugInfo.UNMASKED_RENDERER_WEBGL) || 'unknown';
            }
            vertexShaderPrecision = gl.getShaderPrecisionFormat(gl.VERTEX_SHADER, gl.HIGH_FLOAT).precision.toString();
            fragmentShaderPrecision = gl.getShaderPrecisionFormat(gl.FRAGMENT_SHADER, gl.HIGH_FLOAT).precision.toString();
        } catch (error) {
            
        }
    }

    let audioFingerprint = 'unknown';
    try {
        const audioContext = new (window.AudioContext || window.AudioContext)();
        const oscillator = audioContext.createOscillator();
        const analyser = audioContext.createAnalyser();
        const gain = audioContext.createGain();

        oscillator.type = 'triangle';
        oscillator.frequency.setValueAtTime(10000, audioContext.currentTime); // 10kHz tone
        gain.gain.setValueAtTime(0, audioContext.currentTime); // Mute the sound output

        oscillator.connect(analyser);
        analyser.connect(gain);
        gain.connect(audioContext.destination);

        oscillator.start(0);

        audioFingerprint = await new Promise(resolve => {
            setTimeout(() => {
                const array = new Uint8Array(analyser.frequencyBinCount);
                analyser.getByteFrequencyData(array);
                const fingerprint = array.reduce((acc, val) => acc + val, 0).toString();
                resolve(fingerprint);
                oscillator.stop();
                audioContext.close();
            }, 500);
        });
    } catch (error) {
        
    }

    const maxTouchPoints = navigator.maxTouchPoints || 0;
    const hardwareConcurrency = navigator.hardwareConcurrency || 'unknown';
    const se = await storageEstimate();
  
    const fingerprintComponents = [
        navigator.userAgent,
        navigator.language,
        window.screen.colorDepth.toString(),
        window.screen.width.toString(),
        window.screen.height.toString(),
        window.screen.availWidth.toString(),
        window.screen.availHeight.toString(),
        new Date().getTimezoneOffset().toString(),
        vendor,
        renderer,
        canvas.toDataURL(),
        se.quota,
        se.usage,
        audioFingerprint,
        maxTouchPoints.toString(),
        hardwareConcurrency.toString(),
        vertexShaderPrecision,
        fragmentShaderPrecision,
    ];
  
    return fingerprintComponents.join('');
}

export function hashString(message: string): string {
    return CryptoJS.SHA256(message).toString(CryptoJS.enc.Hex);
}

export async function generateFingerprint() {
    const fingerprintData = await getBrowserFingerprint();
    const fingerprintHash = hashString(fingerprintData);
    return fingerprintHash;
}