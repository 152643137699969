import "./terms_of_use.css";
import React from 'react';

const EFFECTIVE_DATE = "September 18, 2024"

const TermsOfUseReferralProgramSection = () => {
    return (
        <div>
            <h3>
                Referral Program Terms and Conditions
            </h3>
            <p>
                The following terms and conditions apply to the CrowdCash Referral Program ("Program"):
            </p>
            <h4>1. Eligibility</h4>
            <p>
                The Program is open to individuals who are legal residents of the United States, at least 18 years of age, and have an active CrowdCash account.
            </p>
            
            <h4>2. Referral Code</h4>
            <p>
                Upon registration, each user will receive a unique referral code ("Referral Code"). This code can be shared with others, and when used by a new user during sign-up, it will qualify the referrer for referral bonuses.
            </p>
            
            <h4>3. Referral Bonuses</h4>
            <p><strong>For Lenders:</strong></p>
            <p>
                Pre-registration referrals (before CrowdCash's official launch) earn the referrer 50% of the origination fee on the lender's first funded loan. Post-registration referrals earn 33% of the origination fee. Only one referral bonus is awarded per loan.
            </p>
            <p><strong>For Borrowers:</strong></p>
            <p>
                Pre-registration referrals earn the referrer 50% of the origination fee on the borrower's first loan. Post-registration referrals earn 33% of the origination fee. The bonus is paid out only after the borrower makes their first payment.
            </p>

            <h4>4. Payment of Bonuses</h4>
            <p>
                Referral bonuses will be credited to the referrer's CrowdCash account within 30 days of the qualifying action.
            </p>

            <h4>5. Duration and Expiry of Referral Codes</h4>
            <p>
                Pre-registration Referral Codes do not expire. Post-registration codes are valid for 60 days.
            </p>

            <h4>6. Disclosure Requirements</h4>
            <p>
                Participants must disclose their participation in the Program when sharing their Referral Code, in compliance with FTC guidelines.
            </p>

            <h4>7. Privacy</h4>
            <p>
                Participation in the Program requires the collection of personal information, which will be used in accordance with CrowdCash’s Privacy Policy.
            </p>

            <h4>8. Abuse and Termination</h4>
            <p>
                CrowdCash reserves the right to disqualify participants for violating these Terms, engaging in fraudulent activities, or misrepresentation.
            </p>

            <h4>9. Limitation of Liability</h4>
            <p>
                CrowdCash is not responsible for any technical issues, system failures, or other problems that may affect your participation in the Program or your ability to earn referral bonuses.
            </p>

            <h4>10. Governing Law</h4>
            <p>
                These Terms are governed by the laws of the State of Texas. Any disputes arising will be resolved in the courts located in Travis, Texas.
            </p>
        </div>
    );
}

const TermsOfUsePlaidSection = () => {
    return (
        <div>
            <h3>Plaid Services and Data Sharing Consent</h3>
            <p>As part of providing the Service, Crowdcash uses Plaid Inc. (“Plaid”) to gather your data from financial institutions. By using the Service, you grant Crowdcash and Plaid the right, power, and authority to act on your behalf to access and transmit your personal and financial information from relevant financial institutions, as indicated in <a href="https://plaid.com/legal/#end-user-privacy-policy">Plaid’s Privacy Policy.</a> You acknowledge and agree that your personal and financial information will be transferred, stored, and processed by Plaid in accordance with Plaid’s Privacy Policy.</p>
            <p>You further authorize Crowdcash to share with Plaid any necessary information required for Plaid’s services, and you consent to Plaid’s collection, use, and storage of such information. You also acknowledge that Crowdcash is not responsible for the protection of the privacy or security of any information that is transmitted to or collected by Plaid, and any disputes regarding such data must be resolved in accordance with Plaid’s Privacy Policy and applicable law.</p>
        </div>
    );
}

const TermsOfUse = () => {
    return (
        <div className='TermsOfUseContainer'>
            <h1>
                CrowdCash
            </h1>
            <h3>
                Effective Date: {EFFECTIVE_DATE}
            </h3>
            <h2>
                Terms of Use
            </h2>
            <p>
                As used in these Terms & Conditions, Crowdcash means Live Oak Financial LLC.
            </p>
            <p>
                These Terms and Conditions are in addition to any other agreements between you and Crowdcash, 
                including any customer or account agreements and any other agreements that govern your use 
                of software, products, goods, services, content, tools, and information provided by such 
                Crowdcash subsidiary(ies).
            </p>
            <h3>
                General
            </h3>
            <p>
                The Crowdcash website and mobile application (collectively, the “Service”) may include or make available certain content (the “Content”). Content includes, without limitation: (1) account positions, balances, transactions, confirmations, and order history; (2) general news and information, commentary, research reports, educational material and information and data concerning the financial markets, securities and other subjects; (3) market data such as quotations for securities transactions and/or last sale information for completed securities transactions reported in accordance with federal securities regulations; (4) financial and investment interactive tools, such as alerts or calculators; (5) tax preparation, bill payment and account management tools; (6) company names, logos, product and service names, trade names, trademarks and services marks (collectively, “Marks”) owned by Crowdcash, and Marks owned by Third Party Providers (defined below); and (7) any other information, content, services, or software. Certain Content is furnished by third parties (each, a “Third-Party Provider” and collectively, the “Third-Party Providers”). Such Content (“Third Party Content”) includes, without limitation, any information, content, service or software made available by or through social media websites, blogs, wikis, online conferences, telecasts, podcasts, and other forums (collectively, the “Forums”). Third Party Content may be available through framed areas or through hyperlinks to the Third-Party Providers’ websites.
            </p>
            <h3>
                Cancellation of Services and Refund Policy
            </h3>
            <p>
                You may cancel your subscription to our services at any time. To do so, you must log into your account and follow the cancellation instructions available in your account settings. However, refunds are only applicable if you cancel on the same day you subscribe. If you cancel on the day of your subscription, a full refund will be issued.
            </p>
            <p>
                Upon cancellation, you will continue to have access to the subscribed services for the remainder of the current billing period unless the subscription is canceled on the same day it was initiated, in which case access will be terminated immediately, and a refund will be processed. No refunds will be provided for cancellations made after the subscription day.
            </p>
            <h3>
                Acceptance of Terms and Conditions
            </h3>
            <p>
                By using the Service and the Content, you agree to follow and be bound by these Terms and Conditions, including the policies referenced herein. Customers of Crowdcash are granted additional levels of access to the website and their relationship with Crowdcash is governed by additional agreements and terms of use, such as the Customer Agreement that governs their account(s).
            </p>
            <h3>
                Disclaimer and Limitations of Liability
            </h3>
            <p>
                The Content and the Service are provided on an “as is” and “as available” basis. To the fullest extent permitted under applicable law, Crowdcash and the Third-Party Providers expressly disclaim all warranties of any kind with respect to the Content and the Service, whether express or implied, including, but not limited to, the implied warranties of merchantability, fitness for a particular purpose and non-infringement. Neither Crowdcash nor Third-Party Providers guarantee the accuracy, timeliness, completeness or usefulness of any Content. You agree to use the Content and the Service only at your own risk.
            </p>
            <p>
                Neither Crowdcash nor the Third-Party Providers explicitly or implicitly endorse or approve any Third Party Content. Third Party Content is provided for informational purposes only.
            </p>
            <p>
                The Content is not intended to provide financial, legal, tax or investment advice or recommendations, unless specifically identified as an individualized recommendation in writing. You are solely responsible for determining whether any self-directed investment, investment strategy or related transaction is appropriate for you based on your personal investment objectives, financial circumstances and risk tolerance. You should consult your legal or tax professional regarding your specific situation.
            </p>
            <p>
                Crowdcash AND THE THIRD-PARTY PROVIDERS WILL NOT BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES, INCLUDING, BUT NOT LIMITED TO, DAMAGES FOR LOSS OF PROFITS, REVENUE, INCOME, GOODWILL, USE, DATA OR OTHER INTANGIBLE LOSSES (EVEN IF Crowdcash OR ANY THIRD-PARTY PROVIDER HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES), RESULTING FROM: (1) THE USE OF OR THE INABILITY TO USE THE CONTENT OR THE SERVICE; (2) THE COST OF PROCUREMENT OF SUBSTITUTE GOODS AND SERVICES RESULTING FROM ANY GOODS, DATA, INFORMATION OR SERVICES PURCHASED OR OBTAINED OR MESSAGES RECEIVED OR TRANSACTIONS ENTERED INTO, THROUGH OR FROM THE SERVICE; (3) ACCESS TO OR ALTERATION OF YOUR ACCOUNT, TRANSMISSIONS OR DATA DUE TO YOUR CONDUCT, INACTION OR NEGLIGENCE; OR (4) ANY OTHER MATTER RELATING TO THE CONTENT OR THE SERVICE.
            </p>
            <h3>
                Self-Directed Accounts
            </h3>
            <p>
                Crowdcash provides self-directed investors with discount brokerage services and, unless clearly identified in writing to you as an individualized recommendation, Crowdcash does not make recommendations or offer investment advice of any kind. As a self-directed investor, you are solely responsible for evaluating the merits and risks associated with the use of any Content provided through the Service before making any decisions based on such Content. You agree not to hold Crowdcash or any Third-Party Provider liable for any possible claim for damages arising from any self-directed decision you make based on the Content or other information made available to you through the Service or any Third-Party Provider websites. Past performance data should not be construed as indicative of future results.
            </p>
            <h3>
                U.S. Residents Only
            </h3>
            <p>
                The Content and the Service are intended for United States residents only. They shall not be considered a solicitation to any person in any jurisdiction where such solicitation would be illegal.
            </p>
            <h3>
                Content
            </h3>
            <p>
                Content posted on the Service is published as of its stated date or, if no date is stated, the date of first posting. Neither Crowdcash nor the Third-Party Providers have undertaken any duty to update any such information.
            </p>
            <p>
                Crowdcash does not prepare, edit, or endorse Third Party Content. Crowdcash does not guarantee the accuracy, timeliness, completeness or usefulness of Third Party Content, and is not responsible or liable for any content, advertising, products, or other materials on or available from third party sites.
            </p>
            <p>
                You will not hold Crowdcash and/or any Third-Party Provider liable in any way for (a) any inaccuracy of, error or delay in, or omission of the Content; or (b) any loss or damage arising from or occasioned by i) any error or delay in the transmission of such Content; ii) interruption in any such Content due either to any negligent act or omission by any party to any “force majeure” (e.g., flood, extraordinary weather conditions, earthquake or other act of God, fire, war, insurrection, riot, labor dispute, accident, action of government, communications or power failure, equipment or software malfunction), or iii) to any other cause beyond the reasonable control of Crowdcash and/or Third-Party Provider, or iv) non-performance.
            </p>
            <p>
                Any price quotes may be delayed 20 minutes or longer, according to the rules and regulations applicable to exchanges and Third-Party Providers. Neither Crowdcash nor the Third-Party Providers make any representations, warranties or other guarantees as to the accuracy or timeliness of any price quotes. Neither Crowdcash nor the Third-Party Providers make any representations, warranties or other guarantees as to the present or future value or suitability of any sale, trade or other transaction involving any particular security or any other investment.
            </p>
            <p>
                Content is provided exclusively for personal and noncommercial access and use. No part of the Service or Content may be copied, reproduced, republished, uploaded, posted, publicly displayed, encoded, translated, transmitted or distributed in any way (including “mirroring”) to any other computer, server, web site or other medium for publication or distribution or for any commercial enterprise, without Crowdcash’s express prior written consent.
            </p>

            {TermsOfUseReferralProgramSection()}

            <h3>
                Termination; Modification
            </h3>
            <p>
                You agree that, without notice, Crowdcash may terminate these Terms and Conditions, or suspend your access to the Service or the Content, with or without cause at any time and effective immediately. These Terms and Conditions will terminate immediately without notice from Crowdcash if you, in Crowdcash’s sole discretion, fail to comply with any provision of these Terms and Conditions. Crowdcash shall not be liable to you or any third party for the termination or suspension of the Service or the Content, or any claims related to such termination or suspension. Crowdcash and/or the Third-Party Providers may discontinue or modify the Content, or any portion thereof, at any time. You release and agree to indemnify and hold harmless Crowdcash, and the Third-Party Providers, for any loss or damages arising from or relating to such discontinuation or modification.
            </p>
            <h3>
                Communications
            </h3>
            <p>
                By using the Service or the Content, you consent to any form of recording and retention of any communication, information and data exchanged between you and Crowdcash or its representatives or agents.
            </p>
            <p>
                All communications made at or through the Forums are public. Reliance on any Third Party Content available on or through the Forums is at your own risk. When discussing a particular company, stock or security in the Forums, you agree to reveal any ownership interest in such company, stock or security. Without limitation, you agree not to do any of the following:
            </p>
            <p>
                upload, post, transmit or otherwise make available any Content that is unlawful, harmful, threatening, abusive, harassing, tortuous, defamatory, vulgar, obscene, libelous, invasive of another's privacy (including, but not limited to, any address, email, phone number, or any other contact information without the written consent of the owner of such information), hateful, or racially, ethnically or otherwise objectionable;
            </p>
            <p>
                harm minors in any way;
            </p>
            <p>
                impersonate any person or entity, including, but not limited to, (i) a Crowdcash or Third-Party Provider manager, employee, agent, or representative or (ii) forum leader, guide or host;
            </p>
            <p>
                falsely state or otherwise misrepresent your affiliation with any person or entity;
            </p>
            <p>
                forge headers or otherwise manipulate identifiers in order to disguise the origin of any material;
            </p>
            <p>
                upload, post or otherwise transmit any material that you do not have a right to transmit under any law or under contractual or fiduciary relationships (such as inside information, proprietary and confidential information learned or disclosed as part of employment relationships or under nondisclosure agreements);
            </p>
            <p>
                upload, post or otherwise transmit any material that infringes any patent, trademark, trade secret, copyright, rights of privacy or publicity, or other proprietary rights of any party;
            </p>
            <p>
                upload, post, or transmit unsolicited commercial email or “SPAM,” including, but not limited to, unethical marketing, advertising, or any other practice that is in any way connected with SPAM, such as: (1) sending mass email to recipients who haven't requested email from you or with a fake return address; (2) promoting a site with inappropriate links, titles, or descriptions; or (3) promoting any site by posting multiple submissions in forums that are identical;
            </p>
            <p>
                upload, post or otherwise transmit any material that contains software viruses or any other computer code, files or programs designed to interrupt, destroy or limit the functionality of any computer software or hardware or telecommunications equipment;
            </p>
            <p>
                interfere with or disrupt the Service or servers or networks connected to the Service, or disobey any requirements, procedures, policies or regulations of networks connected to the Service; 
            </p>
            <p>
                intentionally or unintentionally violate any applicable local, state, national or international law, including, but not limited to, regulations promulgated by the U.S. Securities and Exchange Commission, any rules of any national or other securities exchange, and any regulations having the force of law;        
            </p>
            <p>
                “stalk” or otherwise harass another;
            </p>
            <p>
                collect or store personal data about other users of the Service;
            </p>
            <p>
                promote or provide instructional information about illegal activities, promote physical harm or injury against any group or individual, or promote any act of cruelty;
            </p>
            <p>
                promote, offer for sale or sell any security or item, good or service that i) violates any applicable federal, state, or local law or regulation, ii) you do not have full power and authority under all relevant laws and regulations to offer and sell, including all necessary licenses and authorizations, or iii) Crowdcash or the Third-Party Providers determine, in their sole discretion, is inappropriate for sale;
            </p>
            <p>
                use the Forums as a forwarding service to another website; or
            </p>
            <p>
                access or otherwise use the Forums in any unlawful manner, for any unlawful purpose or in violation of these Terms and Conditions.
            </p>
            <h3>
                External Links
            </h3>
            <p>
                Crowdcash and/or the Third-Party Providers may provide links to other websites or resources. Because neither Crowdcash or the Third-Party Providers have any control over such sites and resources, you acknowledge and agree that neither Crowdcash nor the Third-Party Providers are responsible for the availability of such external sites or resources. Crowdcash and the Third-Party Providers do not endorse and are not liable for any content, advertising, products, or other materials on or available through such sites or resources. You further acknowledge and agree that neither Crowdcash nor the Third-Party Providers shall be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or services available on or through any such site or resource.
            </p>
            <h3>
                Applicable Policies
            </h3>
            <p>
                In addition to these Terms and Conditions, your access to and use of the Content and the Service is subject to Crowdcash’s then-current policies relating to the Content and the Service, including, without limitation, the Crowdcash Privacy Policy available on the Service. You agree to be bound by these policies and all other Crowdcash policies applicable to the access and use of the Content and the Service.
            </p>
            {TermsOfUsePlaidSection()}
            <p>
                By using the Service, you are consenting to have your personal data transferred to and processed by Crowdcash and its affiliates. As part of providing you the Service, Crowdcash may need to provide you with certain communications, such as service announcements and administrative messages. These communications are considered part of the Service, which you may not be able to opt-out from receiving.
            </p>
            <h3>
                Indemnification
            </h3>
            <p>
                You will indemnify and hold harmless Crowdcash and the Third-Party Providers, and the officers, directors, agents, partners, employees, licensors, distributors, and representatives of Crowdcash and the Third-Party Providers, from and against any and all claims, demands, actions, causes of action, suits, proceedings, losses, damages, costs, and expenses, including reasonable attorneys' fees, arising from or relating to your access and/or use of, or interaction with the Content (including, without limitation, Third Party Content), or any act, error, or omission of your use of your account or any user of your account, in connection therewith, including, but not limited to, matters relating to incorrect, incomplete, or misleading information; libel; invasion of privacy; infringement of a copyright, trade name, trademark, service mark, or other intellectual property; any defective product or any injury or damage to person or property caused by any products sold or otherwise distributed through or in connection with the Service; or violation of any applicable law.
            </p>
            <h3>
                Revisions
            </h3>
            <p>
                Crowdcash may at any time revise these Terms and Conditions by updating this document. You agree to be bound by subsequent revisions and agree to review these Terms and Conditions periodically for changes. The most updated version of this document will be available for your review under the “Crowdcash Terms and Conditions” link that appears on the Crowdcash website and mobile application.
            </p>
            <h3>
                Applicable Law and Venue; Severability
            </h3>
            <p>
                You agree that these Terms and Conditions shall be governed by and interpreted in accordance with the laws of the State of Texas, without giving effect to principles of conflicts of law. Any legal action or proceeding arising under these Terms and Conditions will be brought exclusively in courts located in Travis County, Texas, and you hereby irrevocably consent to the personal jurisdiction and venue therein. If any provision of these Terms and Conditions is deemed unlawful, void or for any reason unenforceable, then that provision will be deemed severable from these Terms and Conditions and will not affect the validity and enforceability of the remaining provisions.
            </p>
        </div>
    );
}

export default TermsOfUse;