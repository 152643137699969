import { useEffect } from 'react';
import { pageLoadedInteraction, pageScrollInteraction } from './interactions.ts';
import { getQueryParams } from './page.ts';

// Declare a global module to extend the Window interface
declare global {
    interface Window {
        scrollTimeout?: number;  // Use number to store a numeric ID for the timeout
    }
}

export const  useScrollAnalytics = (pageName: string) => {
    useEffect(() => {

        const handleScroll = () => {
            // Clear the timeout if it's already set
            if (window.scrollTimeout) {
                clearTimeout(window.scrollTimeout);
            }
            // Set a new timeout
            window.scrollTimeout = setTimeout(() => {
                let data = {
                    page: pageName,
                    scroll: window.scrollY
                };
                pageScrollInteraction(data);
            }, 250) as unknown as number;  // Cast to number if necessary
        };

        // Add scroll event listener to the window
        window.addEventListener('scroll', handleScroll);

        // Cleanup function to remove event listener and clear timeout
        return () => {
            window.removeEventListener('scroll', handleScroll);
            if (window.scrollTimeout) {
                clearTimeout(window.scrollTimeout);
            }
        };
    }, [pageName]);
}

export const useLoadedAnalytics = (pageName: string) => {
    useEffect(()=> {
		const startTime = performance.now();
		const endTime = performance.now();
		const loadTime = endTime - startTime;
		let data = {
			page: pageName,
			loadTime: loadTime
		};
		pageLoadedInteraction(data);
	}, [pageName]);
}

export const useQueryParamStorage = () => {
    useEffect(()=> {
        const queryParams = getQueryParams();
        queryParams.forEach((value, key) => {
            sessionStorage.setItem(key, value);
        });
    }, [])
}