import { useEffect, useState } from 'react';
import styles from './date_range_picker.module.css';
import { ButtonSecondary } from '../../../components/button/button';
import { SelectPrimary } from '../../../components/select/select';

// Helper function to calculate the oldest date based on the selected range
export const calculateOldestDate = (range: string) => {
    const currentDate = new Date();
    switch (range) {
        case 'week':
            return new Date(currentDate.setDate(currentDate.getDate() - 7));
        case 'month':
            return new Date(currentDate.setMonth(currentDate.getMonth() - 1));
        case '3 months':
            return new Date(currentDate.setMonth(currentDate.getMonth() - 3));
        case '6 months':
            return new Date(currentDate.setMonth(currentDate.getMonth() - 6));
        case '9 months':
            return new Date(currentDate.setMonth(currentDate.getMonth() - 9));
        case 'this year':
            return new Date(currentDate.getFullYear(), 0, 1);
        case 'maximum':
            return new Date(currentDate.setFullYear(currentDate.getFullYear() - 2));
        default:
            return new Date();
    }
};

const DashboardDateRangePicker = ({onChangeCb}: {onChangeCb?: (oldest: Date | number, newest: Date | number)=>void}) => {

    const [selectedRange, setSelectedRange] = useState("month");
    const [oldestDate, setOldestDate] = useState<Date | number>(() => calculateOldestDate("month")); //minus selected option duration
    const [newestDate, setNewestDate] = useState<Date | number>(Date.now());

    // Calculate the minimum allowed date (2 years from today)
    const minimumDate = new Date();
    minimumDate.setFullYear(minimumDate.getFullYear() - 2);
    // Calculate the maximum allowed date (today)
    const today = new Date();

    // Reset the dates based on the selected range
    const resetSteps = (e: { target: { value: any; }; }) => {
        const newRange = e.target.value;
        setSelectedRange(newRange);
        setOldestDate(calculateOldestDate(newRange));
        setNewestDate(new Date()); // Reset newest date to current date
    };

    useEffect(()=>{
        if(onChangeCb) {
            onChangeCb(oldestDate, newestDate)
        };
    }, [newestDate, oldestDate, onChangeCb])

    // Step back function to move dates backward by one selected time unit
    const stepBack = () => {
        const currentOldestDate = new Date(oldestDate);
        const currentNewestDate = new Date(newestDate);

        let newOldestDate;
        let newNewestDate;

        switch (selectedRange) {
            case 'week':
                newOldestDate = new Date(currentOldestDate.setDate(currentOldestDate.getDate() - 7));
                newNewestDate = new Date(currentNewestDate.setDate(currentNewestDate.getDate() - 7));
                break;
            case 'month':
                newOldestDate = new Date(currentOldestDate.setMonth(currentOldestDate.getMonth() - 1));
                newNewestDate = new Date(currentNewestDate.setMonth(currentNewestDate.getMonth() - 1));
                break;
            case '3 months':
                newOldestDate = new Date(currentOldestDate.setMonth(currentOldestDate.getMonth() - 3));
                newNewestDate = new Date(currentNewestDate.setMonth(currentNewestDate.getMonth() - 3));
                break;
            case '6 months':
                newOldestDate = new Date(currentOldestDate.setMonth(currentOldestDate.getMonth() - 6));
                newNewestDate = new Date(currentNewestDate.setMonth(currentNewestDate.getMonth() - 6));
                break;
            case '9 months':
                newOldestDate = new Date(currentOldestDate.setMonth(currentOldestDate.getMonth() - 9));
                newNewestDate = new Date(currentNewestDate.setMonth(currentNewestDate.getMonth() - 9));
                break;
            case 'this year':
                newOldestDate = new Date(currentOldestDate.setFullYear(currentOldestDate.getFullYear() - 1));
                newNewestDate = new Date(currentNewestDate.setFullYear(currentNewestDate.getFullYear() - 1));
                break;
            case 'maximum':
                newOldestDate = new Date(currentOldestDate.setFullYear(currentOldestDate.getFullYear() - 2));
                newNewestDate = new Date(currentNewestDate.setFullYear(currentNewestDate.getFullYear() - 2));
                break;
            default:
                return;
        }

        // Prevent stepping back if the newOldestDate is before the minimum date
        if (newOldestDate >= minimumDate) {
            setOldestDate(newOldestDate);
            setNewestDate(newNewestDate);
        } else {
            // If stepping back goes too far, set to the minimum date boundary
            setOldestDate(minimumDate);
        }
    };

    // Step forward function to move dates forward by one selected time unit
    const stepForward = () => {
        const currentOldestDate = new Date(oldestDate);
        const currentNewestDate = new Date(newestDate);

        let newOldestDate;
        let newNewestDate;

        switch (selectedRange) {
            case 'week':
                newOldestDate = new Date(currentOldestDate.setDate(currentOldestDate.getDate() + 7));
                newNewestDate = new Date(currentNewestDate.setDate(currentNewestDate.getDate() + 7));
                break;
            case 'month':
                newOldestDate = new Date(currentOldestDate.setMonth(currentOldestDate.getMonth() + 1));
                newNewestDate = new Date(currentNewestDate.setMonth(currentNewestDate.getMonth() + 1));
                break;
            case '3 months':
                newOldestDate = new Date(currentOldestDate.setMonth(currentOldestDate.getMonth() + 3));
                newNewestDate = new Date(currentNewestDate.setMonth(currentNewestDate.getMonth() + 3));
                break;
            case '6 months':
                newOldestDate = new Date(currentOldestDate.setMonth(currentOldestDate.getMonth() + 6));
                newNewestDate = new Date(currentNewestDate.setMonth(currentNewestDate.getMonth() + 6));
                break;
            case '9 months':
                newOldestDate = new Date(currentOldestDate.setMonth(currentOldestDate.getMonth() + 9));
                newNewestDate = new Date(currentNewestDate.setMonth(currentNewestDate.getMonth() + 9));
                break;
            case 'this year':
                newOldestDate = new Date(currentOldestDate.setFullYear(currentOldestDate.getFullYear() + 1));
                newNewestDate = new Date(currentNewestDate.setFullYear(currentNewestDate.getFullYear() + 1));
                break;
            case 'maximum':
                newOldestDate = new Date(currentOldestDate.setFullYear(currentOldestDate.getFullYear() + 2));
                newNewestDate = new Date(currentNewestDate.setFullYear(currentNewestDate.getFullYear() + 2));
                break;
            default:
                return;
        }

        // Prevent stepping forward if the newNewestDate exceeds today's date
        if (newNewestDate <= today) {
            setOldestDate(newOldestDate);
            setNewestDate(newNewestDate);
        } else {
            // If stepping forward goes too far, set the newest date to today
            setNewestDate(today);
        }
    };

    // Format dates as YYYY-MM-DD
    const formatDate = (date: Date | number) => {
        return new Date(date).toLocaleDateString('en-CA');
    };

    return (
        <div className={styles.DashboardDateRangePicker}>
            <div className={styles.DashboardDateRangePickerControls}>
                <ButtonSecondary onClick={stepBack} style={{rotate: "180deg"}}>{'➤'}</ButtonSecondary>
                <div className={styles.SelectContainer}>
                    <SelectPrimary onChange={resetSteps} defaultValue={"month"}>
                        <option>week</option>
                        <option>month</option>
                        <option>3 months</option>
                        <option>6 months</option>
                        <option>9 months</option>
                        <option>this year</option>
                        <option>maximum</option>
                    </SelectPrimary>
                </div>
                <ButtonSecondary onClick={stepForward}>{"➤"}</ButtonSecondary>
            </div>
            <h3>{formatDate(oldestDate)} to {formatDate(newestDate)}</h3>
        </div>
    );
}

export default DashboardDateRangePicker;