import React from "react"
import styles from "./button.module.css"

export const ButtonCTA = (props: JSX.IntrinsicElements["button"]) => {
    const { children, className, ...rest } = props;
    const combinedClassName = `${styles.Button} ${styles.CTA} ${styles.CTAHover} ${className || ""}`;
    return (
        <button className={combinedClassName} {...rest}>{children}</button>
    );
}

export const ButtonPrimary = (props: JSX.IntrinsicElements["button"]) => {
    const { children, className, ...rest } = props;
    const combinedClassName = `${styles.Button} ${styles.Primary} ${styles.PrimaryHover} ${className || ""}`;
    return (
        <button className={combinedClassName} {...rest}>{children}</button>
    );
}

export const ButtonSecondary = (props: JSX.IntrinsicElements["button"]) => {
    const { children, className, ...rest } = props;
    const combinedClassName = `${styles.Button} ${styles.Secondary} ${styles.SecondaryHover} ${className || ""}`;
    return (
        <button className={combinedClassName} {...rest} {...rest}>{children}</button>
    );
}

export const ButtonWarn = (props: JSX.IntrinsicElements["button"]) => {
    const { children, className, ...rest } = props;
    const combinedClassName = `${styles.Button} ${styles.Warn} ${styles.WarnHover} ${className || ""}`;
    return (
        <button className={combinedClassName} {...rest}>{children}</button>
    );
}