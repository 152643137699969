import { HOME_URL } from './consts.ts';
import { LenderLoanCriteria, User, UserImpl, getUserCookieData } from './user.ts';
import { Loan } from './loan.ts'
const UTILIZATION_API_ENDPOINT = `${HOME_URL}/api/v1/get_utilization`;
const ESTIMATE_BORROWER_LOAN_DETAILS_ENDPOINT = `${HOME_URL}/api/v1/borrower_desired_loan_details`;
const ESTIMATE_INTEREST_RATE_GIVEN_CREDIT_AND_PURPOSE = `${HOME_URL}/api/v1/estimate_interest_rate_given_credit_and_purpose`;
const LENDER_DESRIED_LENDING_DETAILS_ENDPOINT = `${HOME_URL}/api/v1/lender_desired_loan_details`;
const ESTIMATE_BORROWERS_AND_yield_GIVEN_CRITERIA = `${HOME_URL}/api/v1/estimate_borrowers_and_yield_given_criteria`;
const GET_LOANS_ON_MARKET_ENDPOINT = `${HOME_URL}/api/v1/get_loans_on_market`;

const IS_STATE_SUPPORTED_ENDPOINT = `${HOME_URL}/api/v1/is_supported_state`

const SIGN_IN_ENDPOINT = `${HOME_URL}/api/v1/sign_in`;
const SIGN_UP_ENDPOINT = `${HOME_URL}/api/v1/sign_up`;
const FORGOT_PASSWORD_ENDPOINT = `${HOME_URL}/api/v1/forgot_password`;
const RESET_PASSWORD_ENDPOINT = `${HOME_URL}/api/v1/reset_password`
const UPDATE_USER_DATA_ENDPOINT = `${HOME_URL}/api/v1/update_user_data`;
const GET_USER_BY_ID_EMAIL_ENDPOINT = `${HOME_URL}/api/v1/get_user_by_id_email`;

const GET_LOANS_FROM_LENDER = `${HOME_URL}/api/v1/get_loans_from_lender`;
const GET_LOANS_FOR_BORROWER = `${HOME_URL}/api/v1/get_loans_for_borrower`;
const MAKE_ADDITIONAL_PAYMENT = `${HOME_URL}/api/v1/make_additional_payment`;

const UNDERWRITE_LOAN_ENDPOINT = `${HOME_URL}/api/v1/underwrite_loan`;
const LOAN_ACCEPTED_ENDPOINT = `${HOME_URL}/api/v1/on_loan_accepted`;
const LOAN_DECLINED_ENDPOINT = `${HOME_URL}/api/v1/on_loan_declined`;

const GET_LINK_TOKEN_API_ENDPOINT = `${HOME_URL}/api/v1/create_link_token`;
const SEND_LINK_TOKEN_API_ENDPOINT = `${HOME_URL}/api/v1/link_token_exchange`;
const GET_IDENT_VERIF_LINK_API_ENDPOINT = `${HOME_URL}/api/v1/create_verify_link`;

const GET_TRANSACTION_HISTORY_API_ENDPOINT = `${HOME_URL}/api/v1/get_transaction_history`;
const SUBSCRIBE_SERVICE_ENDPOINT = `${HOME_URL}/api/v1/subscribe_service`;
const UNSUBSCRIBE_SERVICE_ENDPOINT = `${HOME_URL}/api/v1/unsubscribe_service`;
const CHECK_SUBSCRIPTION_ENDPOINT = `${HOME_URL}/api/v1/check_subscription`

// TODO: clean this file up into seperate file groupings

export const enum SIGN_IN_STATES {
    "success", //email and passwowd are valid and match
    "not found", //email not found - create account
    "invalid", //password did not match email
}

export interface SignInUpResponse {
    code: number //http status code
    body: string //SIGN_IN_STATES
    resp: string //any message
}

export const sign_in = async (userEmail: string, userPassword: string): Promise<any> => {
    let resp = await fetch(SIGN_IN_ENDPOINT, {
        method: "POST",
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({user_email: userEmail, user_password: userPassword})
    });
    if(resp.ok){
        return await resp.json();
    } else {
        throw new Error(await resp.text());
    }
}

export const sign_up = async (userEmail: string, userPassword: string, referralCode: string, otp: string, utmSource: string | null): Promise<User> => {
    const data = {user_email: userEmail, user_password: userPassword, referral_code: referralCode, otp: otp, utm_source: utmSource};
    let resp = await fetch(SIGN_UP_ENDPOINT, {
        method: "POST",
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(data)
    });
    if(resp.status === 200){
        return await resp.json() as unknown as User;
        // otp success response
    } else if(resp.status === 202) {
        return new UserImpl()
    } else {
        throw new Error(await resp.text());
    }
}

export const forgot_password = async (userEmail: string): Promise<boolean> => {
    let response = await fetch(FORGOT_PASSWORD_ENDPOINT, {
        method: "POST",
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({user_email: userEmail})
    });
    if(response.ok) {
        return true;
    } 
    return false;
}

export const getUserbyIdAndEmail = async (userEmail: string, userId: string): Promise<User> => {
    let resp = await fetch(GET_USER_BY_ID_EMAIL_ENDPOINT, {
        method: "POST",
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({user_id: userId, email: userEmail})
    });
    return await resp.json() as User
}

export const get_loans_from_lender = async (userId: string): Promise<Loan[]> => {
    let resp = await fetch(GET_LOANS_FROM_LENDER, {
        method: "POST",
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
            userId: userId,
        })
    });
    if(resp.ok) {
        resp = await resp.json();
        return resp as unknown as Loan[];
    } else {
        return [];
    }
}

export const get_loans_for_borrower = async (userId: string): Promise<Loan[]> => {
    let resp = await fetch(GET_LOANS_FOR_BORROWER, {
        method: "POST",
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
            userId: userId,
        })
    });
    if(resp.ok) {
        resp = await resp.json();
        return resp as unknown as Loan[];
    } else {
        return [];
    }
}

export const updateUserData = async (userId: string, updates: Partial<User>) => {
    if(!userId) {
        userId = getUserCookieData()!.id
    }

    const updateRequest = {
        user_id: userId,
        user: updates,
    };

    const response = await fetch(UPDATE_USER_DATA_ENDPOINT, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(updateRequest),
    });

    if (!response.ok) {
        console.error('Failed to update user data');
    }
};

export interface UtilizationPayloadResponse {
    Level: string
    CashInUse: number
    CashAvail: number
}

export const getUtilization = async (): Promise<UtilizationPayloadResponse[]> => {
    let resp = await fetch(UTILIZATION_API_ENDPOINT, {
        method: "get", 
        headers: {'Content-Type':'application/json'},
    });
    resp = await resp.json();
    return resp as unknown as UtilizationPayloadResponse[];
}

interface GetTokenResponse {
    expiration: string
    link_token: string
}

export const getCreatedLinkToken = async (userId: string): Promise<GetTokenResponse> => {
    let response = await fetch(GET_LINK_TOKEN_API_ENDPOINT, {
        method: "PSOT",
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({UserId: userId})
    });

    const data = await response.json() as GetTokenResponse;

    if (!data.expiration || !data.link_token) {
        throw new Error("Invalid response from the server");
    }

    return data;
}

export const sendTokenForExchange = async (publicToken: string, institutionName: string, institutionId: string, userId: string): Promise<boolean> => {
    let resp = await fetch(SEND_LINK_TOKEN_API_ENDPOINT, {
        method: "POST",
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({publicToken: publicToken, institutionName: institutionName, institutionId: institutionId, userId: userId})
    });
    if(resp.ok) {
        return true;
    }
    return false;
}

export const getIndentityVerificationToken = async (userId: string): Promise<GetTokenResponse> => {
    let response = await fetch(GET_IDENT_VERIF_LINK_API_ENDPOINT, {
        method: "PSOT",
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({UserId: userId})
    });

    const data = await response.json() as GetTokenResponse;
    if (!data.expiration || !data.link_token) {
        throw new Error("Invalid response from the server");
    }

    return data;
}

export interface TransactionData {
    account_name: string
    account_type: string
    account_subtype: string
    amount: number
    personal_finance_category: string
    detailed_finance_category: string
    date: string
    city: string
    state: string
    pending: boolean
    merchant_name: string
    logo_url: string
}

export interface Liabilities {
    credit: CreditLiability[];
    mortgage: MortgageLiability[];
    student: StudentLiability[];
}
  
export interface CreditLiability {
    account_id: string;
    aprs: Apr[];
    is_overdue: boolean;
    last_payment_amount: number;
    last_payment_date: string;
    last_statement_issue_date: string;
    last_statement_balance: number;
    minimum_payment_amount: number;
    next_payment_due_date: string;
}

export interface Apr {
    apr_percentage: number;
    apr_type: string;
    balance_subject_to_apr: number;
    interest_charge_amount: number;
}

export interface MortgageLiability {
    account_id: string;
    account_number: string;
    current_late_fee: number;
    escrow_balance: number;
    has_pmi: boolean;
    has_prepayment_penalty: boolean;
    interest_rate: InterestRate;
    last_payment_amount: number;
    last_payment_date: string;
    loan_term: string;
    loan_type_description: string;
    maturity_date: string;
    next_monthly_payment: number;
    next_payment_due_date: string;
    origination_date: string;
    origination_principal_amount: number;
    past_due_amount: number;
    property_address: Address;
    ytd_interest_paid: number;
    ytd_principal_paid: number;
}

export interface InterestRate {
    percentage: number;
    type: string;
}

export interface Address {
    city: string;
    country: string;
    postal_code: string;
    region: string;
    street: string;
}

export interface StudentLiability {
    account_id: string;
    account_number: string;
    disbursement_dates: string[];
    expected_payoff_date: string;
    guarantor: string;
    interest_rate_percentage: number;
    is_overdue: boolean;
    last_payment_amount: number;
    last_payment_date: string;
    last_statement_issue_date: string;
    loan_name: string;
    loan_status: LoanStatus;
    minimum_payment_amount: number;
    next_payment_due_date: string;
    origination_date: string;
    origination_principal_amount: number;
    outstanding_interest_amount: number;
    payment_reference_number: string;
    pslf_status: PslfStatus;
    repayment_plan: RepaymentPlan;
    sequence_number: string;
    servicer_address: Address;
    ytd_interest_paid: number;
    ytd_principal_paid: number;
}

export interface LoanStatus {
    end_date: string;
    type: string;
}

export interface PslfStatus {
    estimated_eligibility_date: string;
    payments_made: number;
    payments_remaining: number;
}

export interface RepaymentPlan {
    description: string;
    type: string;
}

export interface AccountData {
    name: string
    official_name: string
    subtype: string
    type: string
    available: number
    current: number
    liabilities: Liabilities
}

export interface BankHistory {
    transactions: TransactionData
    accounts: AccountData
}

export interface BackendPlaidResponse {
    status: number //status code of request - 200: ok, 422: plaid error, 400 & 500: server error
    err: string //error message if error was enountered
    data: any //any data expected from the server
}

export const getTransactionHistory = async (userId: string): Promise<BackendPlaidResponse> => {
    let response = await fetch(GET_TRANSACTION_HISTORY_API_ENDPOINT, {
        method: "POST",
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({UserId: userId})
    });
    return {
        status: response.status,
        err: response.status !== 200 ? await response.text() : null,
        data: response.status === 200 ? await response.json() as BankHistory[] : null
    } as BackendPlaidResponse
}

export const subscribeToService = async (userId: string, subscriptionType: string): Promise<BackendPlaidResponse> => {
    let response = await fetch(SUBSCRIBE_SERVICE_ENDPOINT, {
        method: "POST",
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({UserId: userId, Type: subscriptionType})
    });

    return {
        status: response.status,
        err: response.status !== 200 ? await response.text() : null,
        data: response.status === 200 ? await response.json() : null
    } as BackendPlaidResponse
}

export const unsubscribeFromService = async (userId: string, subscriptionType: string): Promise<BackendPlaidResponse> => {
    let response = await fetch(UNSUBSCRIBE_SERVICE_ENDPOINT, {
        method: "POST",
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({UserId: userId, Type: subscriptionType})
    });

    return {
        status: response.status,
        err: response.status !== 200 ? await response.text() : null,
        data: response.status === 200 ? await response.json() : null
    } as BackendPlaidResponse
}

export const checkSubscriptionService = async (userId: string, subscriptionType: string): Promise<BackendPlaidResponse> => {
    let response = await fetch(CHECK_SUBSCRIPTION_ENDPOINT, {
        method: "POST",
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({UserId: userId, Type: subscriptionType})
    });

    return {
        status: response.status,
        err: response.status !== 200 ? await response.text() : null,
        data: response.status === 200 ? await response.json() : null
    } as BackendPlaidResponse
}

export interface BorrowerEstimatedLoanDetails {
    Id:                   string;
    UserId:               string;
    EmailAddr:            string;
    Fingerprint?:         string;
    IpAdd?:               string;
    EstAt:                Date;
    EstCredit:            number;
    Purpose:              string;
    Amount:               number;
    LoanDetails:          Loan;
}

export const borrowerDesiredLoanDetails = async (details: BorrowerEstimatedLoanDetails): Promise<BorrowerEstimatedLoanDetails> => {
    let resp = await fetch(ESTIMATE_BORROWER_LOAN_DETAILS_ENDPOINT, {
        method: "POST",
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(details)
    });
    if(resp.ok) {
        resp = await resp.json();
        return resp as unknown as BorrowerEstimatedLoanDetails;
    } else {
        throw new Error(await resp.text());
    }
}

export interface EstimateInteresestGivenCreditAndPurposeResp {
    Rate: number;
}

export const estimateInteresestGivenCreditAndPurpose = async (credit: number, purpose: string): Promise<number> => {
    let resp = await fetch(ESTIMATE_INTEREST_RATE_GIVEN_CREDIT_AND_PURPOSE, {
        method: "POST",
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({EstCredit: credit, Purpose: purpose})
    });
    if(resp.ok) {
        resp = await resp.json();
        return resp as unknown as number;
    } else {
        throw new Error(await resp.text());
    }
}

export const fetchLoansOnMarket = async (userId: string): Promise<BorrowerEstimatedLoanDetails[]> => {
    let resp = await fetch(GET_LOANS_ON_MARKET_ENDPOINT, {
        method: "POST",
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({UserId: userId})
    });
    if(resp.ok) {
        resp = await resp.json();
        return resp as unknown as BorrowerEstimatedLoanDetails[];
    } else {
        throw new Error(await resp.text());
    }
}

export interface LenderDesiredLoanDetails {
    Id:           string;
    UserId:       string;
    EmailAddr:    string;
    Fingerprint?: string;
    IpAdd?:       string;
    EstAt:        Date;
    LoanCriteria: LenderLoanCriteria[];
}

export const saveLenderDesiredLoanDetails = async (details: LenderDesiredLoanDetails): Promise<LenderDesiredLoanDetails> => {
    let resp = await fetch(LENDER_DESRIED_LENDING_DETAILS_ENDPOINT, {
        method: "POST",
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(details)
    });
    if(resp.ok) {
        resp = await resp.json();
        return resp as unknown as LenderDesiredLoanDetails;
    } else {
        throw new Error(await resp.text());
    }
}

export interface EstBorrowersAndyieldsResponse {
    EstBorrowers: number;
    Minyield: number;
    Maxyield: number;
}

export const estimateBorrowersAndyieldsGivenCriteria = async (details: LenderLoanCriteria): Promise<EstBorrowersAndyieldsResponse> => {
    let resp = await fetch(ESTIMATE_BORROWERS_AND_yield_GIVEN_CRITERIA, {
        method: "POST",
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(details)
    });
    if(resp.ok) {
        resp = await resp.json();
        return resp as unknown as EstBorrowersAndyieldsResponse;
    } else {
        throw new Error(await resp.text());
    }
}

export interface supportedStatePostResponse {
	IsSupported: boolean
	Country:     string
	State:       string
	StateAbv:    string
	City:        string
	Longitude:   string
	Latitude:    string
}

export const isStateSupported = async (zipCode: string): Promise<supportedStatePostResponse> => {
    let resp = await fetch(`${IS_STATE_SUPPORTED_ENDPOINT}?${zipCode}`, {
        method: "GET",
        headers: {'Content-Type': 'application/json'},
    });
    const data = await resp.json() as unknown as supportedStatePostResponse;
    return data;
}

export interface LoanRequest {
    userId: string; // encrypted borrower's db ID
    amount: number; // pre-scaled amount of the loan desired (should be scaled after struct creation)
    term: number; // term of the loan
    purpose: string; // purpose of the loan, used to determine increased risk
}

export const underwriteLoan = async (details: LoanRequest): Promise<Loan> => {
    let resp = await fetch(UNDERWRITE_LOAN_ENDPOINT, {
        method: "POST",
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(details)
    });
    if(resp.ok) {
        resp = await resp.json();
        return resp as unknown as Loan;
    } else {
        return {} as Loan
    }
}

// return true if the additional payment was successful, false otherwise
export const makeAdditionalPayment = async (loanId: string, amount: number) => {
    let resp = await fetch(MAKE_ADDITIONAL_PAYMENT, {
        method: "POST",
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({loanId: loanId, amount: amount})
    });
    if(resp.ok) {
        return true;
    }
    return false;
}

export const borrowerAccetpedLoanSetActive = (loanId: string)=> {
    const blob = new Blob([JSON.stringify({LoanId: loanId})], { type: 'application/json' });
    navigator.sendBeacon(LOAN_ACCEPTED_ENDPOINT, blob);
}

export const borrowerDeclinedDeleteLoan = (loanId: string) => {
    const blob = new Blob([JSON.stringify({LoanId: loanId})], { type: 'application/json' });
    navigator.sendBeacon(LOAN_DECLINED_ENDPOINT, blob);
}

export const resetPassword = async (secretData: string, newPassword: string): Promise<boolean> => {
    let resp = await fetch(RESET_PASSWORD_ENDPOINT, {
        method: "POST",
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({encData: secretData, newPassword: newPassword})
    });
    if(resp.ok) {
        let data = await resp.json()
        return true;
    }
    return false;
}