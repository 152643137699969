import { HOME_URL } from './consts.ts';
const INTERACTION_API_ENDPOINT = `${HOME_URL}/api/v1/interaction`;

export interface InteractionData {
    visitorIPaddr: string
    type: string
    time: string
    data: any
}

const sendInteraction = (data: InteractionData): Promise<Response> => {
    return new Promise<Response>(async (resolve, reject) => {
        if (navigator.sendBeacon) {
            const success = navigator.sendBeacon(INTERACTION_API_ENDPOINT, JSON.stringify(data));
            if (success) {
                resolve(new Response()); // Resolve immediately if sendBeacon returns true
            } else {
                reject(new Error("sendBeacon failed")); // Reject if sendBeacon returns false
            }
        } else {
            // Fallback to fetch if sendBeacon is not supported
            fetch(INTERACTION_API_ENDPOINT, {
                method: "post",
                headers: {'Content-Type':'application/json'},
                body: JSON.stringify(data)
            }).then(response => {
                if (response.ok) {
                    resolve(response); // Resolve with the response if fetch is successful
                } else {
                    reject(new Error("Fetch failed")); // Reject if fetch fails
                }
            }).catch(error => {
                reject(error); // Reject if fetch encounters an error
            });
        }
    });
}

export const pageLoadedInteraction = async (data: any): Promise<Response> => {
    const requestData: InteractionData = {
        visitorIPaddr: "",
        type: "page load",
        time: new Date().toISOString(),
        data: data
    };
    return sendInteraction(requestData);
};

export const buttonClickInteraction = async (data: any): Promise<Response> => {
    const requestData: InteractionData = {
        visitorIPaddr: "",
        type: "button click",
        time: new Date().toISOString(),
        data: data
    };
    return sendInteraction(requestData);
};

export const pageScrollInteraction = async (data: any): Promise<Response> => {
    const requestData: InteractionData = {
        visitorIPaddr: "",
        type: "page scroll",
        time: new Date().toISOString(),
        data: data
    };
    return sendInteraction(requestData);
};
