import styles from "./tooltip_icon.module.css"
import React, { useState } from 'react';
import { Tooltip } from 'react-tooltip' //https://react-tooltip.com/docs/options
import { generateUuid } from "../../helpers/generate_uuid";
import { MessageModal } from "../my_modal/my_modal";

interface IconProps {
    fill: string
    stroke: string
    strokeWidth: string
}

const Icon = ({fill, stroke, strokeWidth}: IconProps) => {
    return (
        <svg width="100%" height="100%" viewBox="0 0 24 24" fill={fill} xmlns="http://www.w3.org/2000/svg">
            <path d="M12 19H12.01M8.21704 7.69689C8.75753 6.12753 10.2471 5 12 5C14.2091 5 16 6.79086 16 9C16 10.6565 14.9931 12.0778 13.558 12.6852C12.8172 12.9988 12.4468 13.1556 12.3172 13.2767C12.1629 13.4209 12.1336 13.4651 12.061 13.6634C12 13.8299 12 14.0866 12 14.6L12 16" stroke={stroke} strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
}

const ToolTipIcon = ({children}: any) => {
    const uuid = generateUuid();
    const [isShown, setIsShown] = useState(false);

    return (
        <div className={styles.Container}>
            <div style={{backgroundColor: 'var(--text-color-3)', borderRadius: "50%", cursor: 'pointer'}} data-tooltip-id={uuid} onClick={()=>{setIsShown(!isShown)}}>
                <Icon fill={"transparent"} stroke={"var(--bg-color-2)"} strokeWidth={"2"} />
            </div>
            {/* <Tooltip id={uuid} float={true} isShown={isShown} className={styles.Tooltip}>
                {children}
            </Tooltip> */}
            <MessageModal isShown={isShown} setIsShown={setIsShown} message={children}/>
        </div>
    )
}

export default ToolTipIcon;