import React, { useEffect, useRef, useState } from 'react';

import styles from './feature_hero.module.css';
import { ButtonCTA } from '../../../components/button/button.tsx'

const borrowerImages = [
    'assets/borrower_hero_images/financial_tools.webp',
];

const lenderImages = [
    // 'assets/lender_hero_images/secured_default.webp',
    // 'assets/lender_hero_images/get_started_1.webp',
    // 'assets/lender_hero_images/get_started_2.webp',
    'assets/lender_hero_images/make_your_money_work.webp',
    // 'assets/lender_hero_images/move_money_securely.webp', //this one is a weird size
];

const FinancialDashboardImages = [
    'assets/borrower_hero_images/financial_tools.webp',
];

const BorrowerPoints = [
    {
        title: "Tools For Success",
        description: "Loans through us come with a financial tracking tool designed to help you make great financial decisions."
    },
    {
        title: "Competitive Rates",
        description: "Rates With CrowdCash are better because we aren't a bank. We're a community of people helping eachother financially."
    },
    {
        title: "More Than a Credit Score",
        description: "We take your whole financial self into account: your income, bank statements, and current debts."
    },
    {
        title: "Ultra Flexible",
        description: "If it's 3 months, 24 months or anywhere in-between, pay over the exact number of months that make sense for your budget."
    },
];

const LenderPoints = [
    {
        title: "Defaults can be Secured",
        description: "Crowdcash will completely refund your portion of the loan's principal if a Borrower defaults before making any payments."
    },
    {
        title: "Simple and Effective Criteria",
        description: "Manually or automatically funds loans, or portions of loans based on: Risk Score, Loan term, and Loan Amount."
    },
    {
        title: "Using The Best Tech",
        description: "Funds move securely, directly between everyone's accounts. You get your account's savings rate if you don't lend any money."
    },
    {
        title: "Get started with as little as $50",
        description: (
            <>
                <p>$50 with 12mo term @ 11.14% APY plus 5% origination.</p>
                <p>Super-prime Lenders with a 1.14% default rate.</p>
                <p>Crowdcash takes a 10% service fee from the total interest.</p>
                <p>($52.5 * 1.1114) - ($52.5 * 0.0114) - ($52.5 * 0.01) = <span>$57.22</span>.</p>
            </>
        )
    }
];

const FinancialDashboardPoints = [
    {
        title: "Better Budgeting",
        description: "Categorized transaction summaries help see where your money is going. Quickly spot areas of overspending, track progress against budgets, and find opportunities to cut costs."
    },
    {
        title: "Consolidated View",
        description: "Don't log into multiple bank accounts. Instead, get a single view of your entire financial picture, including balances, transactions, and spending categories."
    },
    {
        title: "Improved Decision Making",
        description: "With all your financial data at hand, you can make better decisions about how to allocate your resources, adjust your budget, or plan for future expenses."
    },
    {
        title: "Perfectly Low Price",
        description: "Powerful and real time tracking at only $1.67 per connected account per month."
    }
]

const FeaturePoints = ({points}: {points: {title: string, description: string | JSX.Element}[]}) => {
    const refs = useRef(points.map(() => React.createRef()));
    const [visibleIndices, setVisibleIndices] = useState<number[]>([]);

    useEffect(() => {
        const observers = refs.current.map((_ref: any, index: number) => {
            return new IntersectionObserver(
                (entries) => {
                    entries.forEach((entry) => {
                        if (entry.isIntersecting) {
                            // Add index to visibleIndices if it becomes visible
                            setTimeout(() => {
                                setVisibleIndices((prevVisibleIndices) => {
                                    // Ensure we are not duplicating indices in the array
                                    if (!prevVisibleIndices.includes(index)) {
                                        return [...prevVisibleIndices, index];
                                    }
                                    return prevVisibleIndices;
                                });
                            }, index * 100); // Adds a delay for each group in sequence
                        } else {
                            // Remove index from visibleIndices if it becomes invisible
                            setVisibleIndices((prevVisibleIndices) =>
                                prevVisibleIndices.filter((i) => i !== index)
                            );
                        }
                    });
                },
                { threshold: 0.2 }
            );
        });

        refs.current.forEach((ref: { current: any; }, index: number) => {
            if (ref.current) observers[index].observe(ref.current);
        });

        return () => {
            refs.current.forEach((ref: { current: any; }, index: number) => {
                if (ref.current) observers[index].disconnect(ref.current);
            });
        };
    }, []);

    return (
        <>
            {points.map((point, index: number) => (
                <div
                    ref={refs.current[index]}
                    key={index}
                    className={`${styles.pointGroup} ${visibleIndices.includes(index) ? styles.fadeIn : styles.fadeOut}`}
                >
                    <h3>{point.title}</h3>
                    <div>{point.description}</div>
                </div>
            ))}
        </>
    );
};

const FeatureSection = ({buttonText, onClick, images, imagePlacement, featurePoints}) => {
    const detailsRef = useRef(null);
    const buttonRef = useRef(null);

    const [detailsVisible, setDetailsVisible] = useState(true);
    const [buttonVisible, setButtonVisible] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        if (entry.target === detailsRef.current) {
                            setDetailsVisible(true);
                        } else if (entry.target === buttonRef.current) {
                            setButtonVisible(true);
                        }
                    } else if(!entry.isIntersecting) {
                        if (entry.target === detailsRef.current) {
                            setDetailsVisible(false);
                        } else if (entry.target === buttonRef.current) {
                            setButtonVisible(false);
                        }
                    }
                });
            },
            { threshold: 0.1 }
        );

        if (detailsRef.current) observer.observe(detailsRef.current);
        if (buttonRef.current) observer.observe(buttonRef.current);

        return () => {
            if (detailsRef.current) observer.unobserve(detailsRef.current);
            if (buttonRef.current) observer.unobserve(buttonRef.current);
        };
    }, []);

    const [currentImageIndex, setCurrentImageIndex] = useState(0);


    return (
        <>
            <div ref={detailsRef} className={`${styles.DetailsContainer} ${detailsVisible ? styles.fadeIn : styles.fadeOut}`}>
                {imagePlacement === "top" ? <img src={images[currentImageIndex]} alt=''/> : null}
                <div className={styles.PointsContainer}>
                    {featurePoints}
                </div>
                {imagePlacement === "bot" ? <img src={images[currentImageIndex]} alt=''/> : null}
            </div>
            <div ref={buttonRef} className={`${buttonVisible ? styles.fadeIn : styles.fadeOut}`}>
                <ButtonCTA onClick={onClick}>{buttonText}</ButtonCTA>
            </div>
        </>
    );
}

const FeatureHero = ({takeOutLoanClick, becomeLenderClick, financialDashboardClick})=> {

    const headingRef = useRef(null);
  
    const [headingVisible, setHeadingVisible] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        if (entry.target === headingRef.current) {
                            setHeadingVisible(true);
                        }
                    } else if(!entry.isIntersecting) {
                        if (entry.target === headingRef.current) {
                            setHeadingVisible(false);
                        }
                    }
                });
            },
            { threshold: 0.3 }
        );

        if (headingRef.current) observer.observe(headingRef.current);

        return () => {
            if (headingRef.current) observer.unobserve(headingRef.current);
        };
    }, []);    

    return (
        <div className={styles.FutureHeroContainer}>
            <div ref={headingRef} className={`${styles.HeadingContainer} ${headingVisible ? styles.fadeIn : styles.fadeOut}`}>
                <h5>Key Features</h5>
                <h1>Empowering Your Financial Journey</h1>
                <h4>Discover the power of our peer-to-peer fintech platform, designed to revolutionize the way you manage your finances.</h4>
            </div>
            {/* <h2>Borrowers</h2> */}
            <FeatureSection buttonText={"Take out a Loan"} onClick={takeOutLoanClick} imagePlacement="top" images={borrowerImages} featurePoints={<FeaturePoints points={BorrowerPoints} />}/>
            {/* <h2>Lenders</h2> */}
            <FeatureSection buttonText={"Become a Lender"} onClick={becomeLenderClick} imagePlacement="bot" images={lenderImages} featurePoints={<FeaturePoints points={LenderPoints}/>}/>
            {/* <h2>Financial Dashboard</h2> */}
            <FeatureSection buttonText={"Track your Finances"} onClick={financialDashboardClick} imagePlacement="top" images={FinancialDashboardImages} featurePoints={<FeaturePoints points={FinancialDashboardPoints}/>}/>
        </div>
    );
}

export default FeatureHero;