import './toggle_input.css';

import React, { useEffect, useState } from 'react';

interface ToggleSwitchProps {
    selectedIndex?: number; // Controlled prop from the parent
    initialOptIndex?: number; // Initial index for internal state when uncontrolled
    toggleCB?: (selectedIndex: number) => void;
    options: string[];
}

const ToggleSwitch = ({ selectedIndex, initialOptIndex = 0, toggleCB, options }: ToggleSwitchProps) => {
    const [internalIndex, setInternalIndex] = useState(initialOptIndex);

    // Determine if the component is controlled or uncontrolled
    const isControlled = selectedIndex !== undefined;

    // Update internal state if the component is uncontrolled and a new initial index is passed
    useEffect(() => {
        if (!isControlled) {
            setInternalIndex(initialOptIndex);
        }
    }, [initialOptIndex, isControlled]);

    // Handler for changing the option
    const selectOption = (index: number) => {
        if (!isControlled) {
            setInternalIndex(index); // Update internal state if uncontrolled
        }
        if (toggleCB) {
            toggleCB(index); // Notify parent via callback
        }
    };

    // Use controlled state if provided, otherwise fallback to internal state
    const currentIndex = isControlled ? selectedIndex! : internalIndex;

    return (
        <div className="toggle-switch">
            <div 
                className="slider" 
                style={{ 
                    left: `${(100 / options.length) * currentIndex}%`, 
                    width: `${100 / options.length}%`
                }}
            >
                <span>{options[currentIndex]}</span>
            </div>
            <div className="labels">
                {options.map((option, index) => (
                    <span 
                        key={index} 
                        className={`label ${currentIndex === index ? 'active' : ''}`}
                        onClick={() => selectOption(index)}
                    >
                        {option}
                    </span>
                ))}
            </div>
        </div>
    );
};

export default ToggleSwitch;