import styles from './desiredLoanForm.module.css'

import React, { useEffect, useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { borrowerDesiredLoanDetails, BorrowerEstimatedLoanDetails, estimateInteresestGivenCreditAndPurpose } from '../../../../helpers/api.ts';
import { ButtonCTA } from '../../../../components/button/button.tsx';
import { SelectSecondary } from '../../../../components/select/select.tsx';
import { RangeSlider } from '../../../../components/input/input.tsx';
import { Loan, LOAN_DETAIL_CONSTS, LoanCalc } from '../../../../helpers/loan.ts';
import { generateFingerprint } from '../../../../helpers/fingerprint.ts';
import { ErrorMessageModal } from '../../../../components/my_modal/my_modal.tsx';

/* a loan costs:
1.19 identity document verification
0.238 selfie identity verification
1.275 bank auth

0.276/account/month transactions
0.184/account/month liabilities

profit = (origination_fee + (0.1 * total_interest)) - ((transactions + liabilities) * account * term) - identity_verif - selfie_verif - bank_auth
*/

const ESTIMATED_LOAN_DETAILS_DISCLAIMER = `The loan details provided herein are based on preliminary estimates and are not final. These estimates are generated using the initial information you have provided and are subject to change. As additional personal details are input and verified, a more precise and accurate loan quote will be generated. Please be aware that the terms, interest rates, and loan amounts indicated at this stage are only indicative and may vary once the underwriting process is completed and all relevant financial information is reviewed. Final loan approval and terms are contingent upon a comprehensive evaluation of your financial status, including but not limited to credit history, income verification, and other necessary personal and financial details.`;

const CustomTooltip = ({ active, payload, label }: {active?: any, payload?: any, label?: any}) => {
    if (active && payload && payload.length) {
        return (
            <div className={styles.customTooltip}>
                <p className={styles.label}>{label}</p>
                <ul className={styles.tooltipList}>
                {payload.map((entry: any, index: number) => (
                    <li key={`${index}`} style={{ color: entry.fill }}>
                        {entry.name}: ${entry.value.toFixed(2)}
                    </li>
                ))}
                </ul>
            </div>
        );
    }
    return null;
};

// TODO: should also get/return a loanId uuid to track this loan to user session
const LoanEstimateForm = ({estLoanDetails, incProgressCb}: {estLoanDetails: BorrowerEstimatedLoanDetails, incProgressCb: (borrowerEstimatedLoanDetails: BorrowerEstimatedLoanDetails)=>void}) => {
    const [showErrMsgModal, setShowErrMsgModal] = useState(false);
    const [errMsgModalText, setErrMsgModalText] = useState<string[]>([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    // loan desired by borrower initially set when component is called, incProgressCb sets this value in the parent component
    const [loanDetails, setLoanDetails] = useState<BorrowerEstimatedLoanDetails>(estLoanDetails)
    const [estInterestRate, setEstInterestrate] = useState(10 * LoanCalc.scaleFactor); //base 10%
    // true if the form is filled out and user can proceed in application
    const [isFormValid, setIsFormValid] = useState(false);

    // array of loan options available to user
    const [possibleLoanDetails, setPossibleDetailsData] = useState<Loan[]>([]);
    // index of possibleLoanDetails selected by user
    const [possibleLoanDetailsSelectedIndex, setPossibleLoanDetailsSelectedIndex] = useState(0);

    const onLoanPurposeChange = (e: { target: { value: any; }; }) => {
        setLoanDetails(prev=>({
            ...prev,
            Purpose: e.target.value
        }));
    }

    const onLoanAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPossibleLoanDetailsSelectedIndex(0);
        setLoanDetails(prev=>({
            ...prev,
            Amount: parseFloat(e.target.value),
        }));
    }

    const onCreditScoreEstimate = (e: { target: { value: any; }; }) => {
        setPossibleLoanDetailsSelectedIndex(0);
        setLoanDetails(prev=>({
            ...prev,
            EstCredit: parseInt(e.target.value)
        }));
    }

    const onLoanTermChange = (e: any) => {
        setPossibleLoanDetailsSelectedIndex(e.target.value - 1);
    }

    const onMonthlyPaymentChange = (e: string) => {
        for (let i = possibleLoanDetails.length - 1; i >= 0 ; i--) {
            const formatted = '$' + (possibleLoanDetails[i].monthlyPayment/1000).toFixed(2);
            if(formatted === e) {
                setPossibleLoanDetailsSelectedIndex(i);
                break;
            }
        }
    }

    useEffect(()=> {
        // debouce
        const timer = setTimeout(() => {
            if(loanDetails.EstCredit >= 0 && loanDetails.EstCredit <= 850 && loanDetails.Purpose.length > 1) {
                estimateInteresestGivenCreditAndPurpose(loanDetails.EstCredit, loanDetails.Purpose).then((resp)=>{
                    setEstInterestrate(resp);
                })
            }
        }, 750);
        return () => clearTimeout(timer);
    }, [loanDetails.EstCredit, loanDetails.Purpose])

    useEffect(()=>{
        setLoanDetails(prev=>({
            ...prev,
            EstimatedLoanDetails: possibleLoanDetails[possibleLoanDetailsSelectedIndex]
        }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [possibleLoanDetails.length, possibleLoanDetailsSelectedIndex])

    useEffect(()=>{
        let isValid = true;
        isValid = isValid && loanDetails.Purpose.length > 1;
        isValid = isValid && loanDetails.EstCredit >= 0 && loanDetails.EstCredit <= 850;
        setIsFormValid(isValid);
    },[loanDetails])

    const onSubmit = async () => {
        setIsSubmitting(true);
        if(incProgressCb) {
            try {
                let ld: BorrowerEstimatedLoanDetails = {
                    Id: '',
                    UserId: '',
                    EmailAddr: '',
                    EstAt: new Date(),
                    Fingerprint: await generateFingerprint(),
                    EstCredit: loanDetails.EstCredit,
                    Purpose: loanDetails.Purpose,
                    Amount: loanDetails.Amount * LoanCalc.scaleFactor,
                    LoanDetails: possibleLoanDetails[possibleLoanDetailsSelectedIndex]
                }
                ld = await borrowerDesiredLoanDetails(ld); //will contain some updates relevant to this user if they sign up
                setIsSubmitting(false);
                incProgressCb(ld);
            } catch (error: any) {
                setErrMsgModalText([error.message]);
                setShowErrMsgModal(true);
            }
        }
        setIsSubmitting(false);
    }

    useEffect(()=>{
        let _possibleLoanDetails: Loan[] = [];

        for (let term = 1; term < LOAN_DETAIL_CONSTS.MAX_LOAN_TERM + 1; term++) {
            const estimatedLoan = LoanCalc.NewEstimatedLoan(loanDetails.Amount, term, estInterestRate);
                if(estimatedLoan != null) {
                    _possibleLoanDetails.push(estimatedLoan);
                }

            setPossibleDetailsData(_possibleLoanDetails);
        }

    }, [loanDetails, estInterestRate])

    return (
            <div className={styles.LoanInfoForm} onSubmit={onSubmit}>
                <ErrorMessageModal isShown={showErrMsgModal} setIsShown={setShowErrMsgModal} messages={errMsgModalText}/>
                <div className={styles.DualInputSection}>
                    <div className={styles.InputSection}>
                        <h3 className={styles.InputTitle}>Loan Purpose</h3>
                        <SelectSecondary value={loanDetails.Purpose} onChange={onLoanPurposeChange}>
                            <option value="" disabled hidden></option>
                            <option value="Debt Consolidation">Debt Consolidation</option>
                            <option value="Credit Card Refinance">Credit Card Refinance</option>
                            <option value="Major Purchase">Major Purchase</option>
                            <option value="Home Improvement">Home Improvement</option>
                            <option value="Vacation">Vacation</option>
                            <option value="Wedding Expense">Wedding Expense</option>
                            <option value="Moving and Relocation">Moving and Relocation</option>
                            <option value="Medical Expense">Medical Expense</option>
                            <option value="Car Repair">Car Repair</option>
                            <option value="Everyday Bills">Everyday Bills</option>
                            <option value="Other">Other</option>
                        </SelectSecondary>
                    </div>

                    <div className={styles.InputSection}>
                        <h3 className={styles.InputTitle}>Estimate your Credit</h3>
                        <RangeSlider min={0} max={850} step={10} showLabel={true} initialValue={720} onChange={onCreditScoreEstimate}/>
                    </div>
                </div>

                <div className={styles.DualInputSection}>
                    <div className={styles.InputSection}>
                        <h3 className={styles.InputTitle}>Loan Amount ($ USD)</h3>
                        <RangeSlider min={LOAN_DETAIL_CONSTS.MIN_LOANABLE} max={LOAN_DETAIL_CONSTS.MAX_LOANABLE} step={LOAN_DETAIL_CONSTS.STEP_LOANABLE} showLabel={true} initialValue={LOAN_DETAIL_CONSTS.MIN_LOANABLE} onChange={onLoanAmountChange}/>
                    </div>

                    <div className={styles.InputSection}>
                        <h3 className={styles.InputTitle}>Loan Term (in months)</h3>
                        {possibleLoanDetails[0] ? 
                            <SelectSecondary value={possibleLoanDetails[possibleLoanDetailsSelectedIndex].loanTerm} onChange={onLoanTermChange}>
                                <option value="" disabled hidden></option>
                                {possibleLoanDetails.map((loanDetail, _)=>{
                                    return (
                                        <option key={loanDetail.loanTerm} value={loanDetail.loanTerm}>
                                            {loanDetail.loanTerm}
                                        </option>
                                    );
                                })}
                            </SelectSecondary>
                            :
                            <SelectSecondary><option value="" disabled hidden></option></SelectSecondary>
                        }
                        
                    </div>
                </div>

                <div className={styles.InputSection}>
                    <h3 className={styles.InputTitle}>Choose your Monthly Payment</h3>
                    {possibleLoanDetails[0] ? 
                        <RangeSlider 
                            min={0} 
                            max={possibleLoanDetails.length - 1} 
                            showLabel={true}
                            controlledValue={possibleLoanDetailsSelectedIndex}
                            stepValues={possibleLoanDetails.map((ld, _)=>{return '$' + (ld.monthlyPayment/1000).toFixed(2)})} 
                            onChange={onMonthlyPaymentChange}
                        /> 
                        : 
                        null
                    }
                </div>

                <div className={styles.LoanDetailsReadout}>
                    <p>APR: {(possibleLoanDetails[possibleLoanDetailsSelectedIndex]?.loanApr/LoanCalc.scaleFactor).toFixed(2)}%</p>
                    <p>Total Interest: ${(possibleLoanDetails[possibleLoanDetailsSelectedIndex]?.expectedInterestInCents/LoanCalc.scaleFactor).toFixed(2)}</p>
                    <p>Last Payment: {`${new Date(new Date().getFullYear(), new Date().getMonth() + 1 + possibleLoanDetails[possibleLoanDetailsSelectedIndex]?.loanTerm, 1).toDateString()}`}</p>
                    <p>First Payment: {`${new Date(new Date().getFullYear(), new Date().getMonth() + 1, 1).toDateString()}`}</p>
                    <p>APY: {(possibleLoanDetails[possibleLoanDetailsSelectedIndex]?.loanInterestRate / LoanCalc.scaleFactor)}%</p>
                    <p>Origination Fee: ${(possibleLoanDetails[possibleLoanDetailsSelectedIndex]?.originationFeeInCents/LoanCalc.scaleFactor).toFixed(2)}</p>
                </div>

                <div className={styles.ChartContainer}>
                    <h3 className={styles.ChartTitle}>Amortization</h3>
                    <ResponsiveContainer width="100%" height="100%">
                        <BarChart
                            data={
                                possibleLoanDetails[possibleLoanDetailsSelectedIndex]?.expectedPayments.map((_, idx)=>{
                                    return {
                                        name: `Month ${idx + 1}`,
                                        interest: possibleLoanDetails[possibleLoanDetailsSelectedIndex]?.expectedPayments[idx].interest/LoanCalc.scaleFactor,
                                        principal: possibleLoanDetails[possibleLoanDetailsSelectedIndex]?.expectedPayments[idx].principal/LoanCalc.scaleFactor
                                    };
                                })
                            }
                            margin={{
                                top: 20,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name"/>
                            <YAxis />
                            <Tooltip content={<CustomTooltip />} />
                            <Legend />
                            <Bar dataKey="principal" stackId="a" fill="var(--pos-color)" />
                            <Bar dataKey="interest" stackId="a" fill="var(--neg-color)" />
                        </BarChart>
                    </ResponsiveContainer>
                </div>

                <div className={styles.ButtonContainer}>
                    <ButtonCTA onClick={onSubmit} disabled={!isFormValid || isSubmitting}>{isSubmitting ? "...": "Continue"}</ButtonCTA>
                    <small>Continuing will not affect your credit score</small>
                </div>

                <p className={styles.Disclaimer}>{ESTIMATED_LOAN_DETAILS_DISCLAIMER}</p>

            </div>
    );
}

export default LoanEstimateForm