import { useEffect, useState } from "react";
import styles from './pie_chart.module.css';
import { TransactionData } from "../../../helpers/api";
import { Pie, PieChart, ResponsiveContainer, Sector } from "recharts";

const categoryMappingName: { [key: string]: string } = {
    GENERAL_MERCHANDISE: "Shopping",
    GOVERNMENT_AND_NON_PROFIT: "Gov & NonProfit",
    ENTERTAINMENT: "Entertainment",
    FOOD_AND_DRINK: "Food & Drink",
    TRANSPORTATION: "Transport",
    TRAVEL: "Travel",
    LOAN_PAYMENTS: "Loan Payment",
    BANK_FEES: "Bank Fees",
    HOME_IMPROVEMENT: "Home Improvement",
    MEDICAL: "Health",
    GENERAL_SERVICES: "Services",
    TRANSFERS: "Transfers",
    RENT_AND_UTILITIES: "Rent & Utilities",
    PERSONAL_CARE: "Personal Care",
    INCOME: "Income",
    TRANSFER_IN: "Transfer IN",
    TRANSFER_OUT: "Transfer OUT",
};

const categoryMappingFillColor: { [key: string]: string } = {
    TRANSFERS: "var(--accent-color-1)",
    LOAN_PAYMENTS: "var(--accent-color-2)",
    BANK_FEES: "var(--accent-color-3)",
    ENTERTAINMENT: "var(--accent-color-4)",
    FOOD_AND_DRINK: "var(--accent-color-5)",
    GENERAL_MERCHANDISE: "var(--accent-color-6)",
    HOME_IMPROVEMENT: "var(--accent-color-7)",
    MEDICAL: "var(--accent-color-8)",
    GENERAL_SERVICES: "var(--accent-color-9)",
    GOVERNMENT_AND_NON_PROFIT: "var(--accent-color-10)",
    TRANSPORTATION : "var(--accent-color-11)",
    TRAVEL: "var(--accent-color-12)",
    RENT_AND_UTILITIES: "var(--accent-color-13)",
    PERSONAL_CARE: "var(--accent-color-14)",
    INCOME: "var(--accent-color-15)",
    TRANSFER_IN: "var(--accent-color-16)",
    TRANSFER_OUT: "var(--accent-color-17)",
};

const ExpensePieChart = ({data}: {data: TransactionData[]}) => {

    const [activeIndex, setActiveIndex] = useState(0);
    const [pauseInterval, setPauseInterval] = useState(false);

    const onPieEnter = (_: any, index: number) => {
        setActiveIndex(index);
        setPauseInterval(true);
    };

    const transformedData = data.reduce((acc: any[], transaction) => {
        const category = categoryMappingName[transaction.personal_finance_category] || "Other";
        const amount = transaction.amount;

        // Check if the category already exists in the accumulator
        const existingCategory = acc.find((entry) => entry.name === category);

        if (existingCategory) {
            existingCategory.amount += amount;
        } else {
            acc.push({
                name: category,
                amount: amount,
                personal_finance_category: transaction.personal_finance_category,
                fill: categoryMappingFillColor[transaction.personal_finance_category]
            });
        }

        return acc;
    }, []);

    useEffect(() => {
        let interval: NodeJS.Timeout;

        if (!pauseInterval) {
            interval = setInterval(() => {
                setActiveIndex((prevIndex) =>
                    (prevIndex + 1) >= transformedData.length ? 0 : prevIndex + 1
                );
            }, 5000);
        }

        return () => clearInterval(interval);
    }, [pauseInterval, transformedData.length]);

    useEffect(() => {
        // Reset the pause state after 5 seconds if it was triggered by a hover
        if (pauseInterval) {
            const timeout = setTimeout(() => {
                setPauseInterval(false);
            }, 5000);

            return () => clearTimeout(timeout);
        }
    }, [pauseInterval]);

    const renderActiveShape = (props: any) => {
        const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, payload, value } = props;
    
        return (
            <g>
                <text x={cx} y={cy} dy={-10} textAnchor="middle" fill={categoryMappingFillColor[payload.personal_finance_category]} fontSize={16}>
                    {categoryMappingName[payload.personal_finance_category]}
                </text>
                <text x={cx} y={cy} dy={16} textAnchor="middle" fill={categoryMappingFillColor[payload.personal_finance_category]} fontSize={14}>
                    {`$${value.toFixed(2)}`}
                </text>
                <Sector
                    cx={cx}
                    cy={cy}
                    innerRadius={innerRadius}
                    outerRadius={outerRadius}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    fill={categoryMappingFillColor[payload.personal_finance_category]}
                />
                <Sector
                    cx={cx}
                    cy={cy}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    innerRadius={outerRadius + 6}
                    outerRadius={outerRadius + 10}
                    fill={categoryMappingFillColor[payload.personal_finance_category]}
                />
            </g>
        );
    };

    return (
        <div className={styles.ExpensePieContiner}>
            <ResponsiveContainer>
                <PieChart>
                    <Pie
                        activeIndex={activeIndex}
                        activeShape={renderActiveShape}
                        onMouseEnter={onPieEnter}
                        dataKey="amount"
                        data={transformedData}
                        cx="50%" cy="50%" innerRadius={"60%"} outerRadius={"80%"} strokeWidth={0}
                        animationDuration={1000}
                    />
                </PieChart>
            </ResponsiveContainer>
        </div>
    );
}

export default ExpensePieChart;