import styles from "./filter_view.module.css"
import { DualRangeSlider } from "../../../components/input/input";
import { useEffect, useState } from "react";

interface MarketPlaceLoan {
    Id: string //id matching loan in DB

    RiskRating: number; //credit score for now
    Purpose: string;
    MonthlyPayment: number;
    Amount: number;
    Duration: number;
    Yield: number;
    DateSubmitted: Date;
    // below hidden until expanded - isPlaidLinked must be true to see details
    BorrowerRate: number;
    DateEnds: Date;
    DTI: number;
    IncomeRange: number;
    EmploymentStatus: string;
    Occupation: string;
    State: string;
    OLOC: number; //open lines of credit
    Deliquincies: number;
}

export interface MinMaxValues {
    Min: number;
    Max: number;
}

export interface FilterValues {
    Amount: MinMaxValues;
    RiskRating: MinMaxValues;
    MonthlyPayment: MinMaxValues;
    TermLength: MinMaxValues;
    Yield: MinMaxValues;
}

const FilterView = ({filterSettings, onFilterChange}: {filterSettings?: FilterValues, onFilterChange?: (filter: FilterValues)=>void}) => {
    
    const FILTER_SETTINGS = {
        Amount: {Min: 250, Max: 5_000},
        RiskRating: {Min: 0, Max: 850},
        MonthlyPayment: {Min: 0, Max: 10_000},
        TermLength: {Min: 1, Max: 24},
        Yield: {Min: 0, Max: 100}
    }

    const [filter, setFilter] = useState<FilterValues>(filterSettings ? filterSettings : {
        Amount: {Min: 250, Max: 5_000},
        RiskRating: {Min: 0, Max: 850},
        MonthlyPayment: {Min: 50, Max: 10_000},
        TermLength: {Min: 1, Max: 24},
        Yield: {Min: 0, Max: 100},
    });

    // fires every 100ms to prevent device resource use on every little update
    useEffect(()=>{
        // debouce
        const timer = setTimeout(() => {
            if(onFilterChange) onFilterChange(filter);
        }, 100);

        // Cleanup the timeout if criteria changes before the 2 seconds
        return () => clearTimeout(timer);
    },[filter, onFilterChange])

    const onAmountChange = (minValue: number, maxValue: number) => {
        setFilter({...filter, Amount: {Min: minValue, Max: maxValue}})
    }

    const onRiskRatingChange= (minValue: number, maxValue: number) => {
        setFilter({...filter, RiskRating: {Min: minValue, Max: maxValue}})
    }

    const onMonthlyPaymentChange= (minValue: number, maxValue: number) => {
        setFilter({...filter, MonthlyPayment: {Min: minValue, Max: maxValue}})
    }

    const onTermLengthChange= (minValue: number, maxValue: number) => {
        setFilter({...filter, TermLength: {Min: minValue, Max: maxValue}})
    }

    const onYieldChange= (minValue: number, maxValue: number) => {
        setFilter({...filter, Yield: {Min: minValue, Max: maxValue}})
    }

    return (
        <div className={styles.MarketFilterContainer}>
            {/* amount */}
            <div className={styles.FilterCriteriaSection}>
                <h3>Amount: ${filter.Amount.Min} - {filter.Amount.Max}</h3>
                <DualRangeSlider 
                    min={FILTER_SETTINGS.Amount.Min} 
                    max={FILTER_SETTINGS.Amount.Max} 
                    step={50} 
                    onChange={onAmountChange} 

                    initialMinValue={filter.Amount.Min} 
                    initialMaxValue={filter.Amount.Max}
                />
            </div>
            
            {/* risk rating */}
            <div className={styles.FilterCriteriaSection}>
                <h3>Credit Score: {filter.RiskRating.Min} - {filter.RiskRating.Max}</h3>
                <DualRangeSlider 
                    min={FILTER_SETTINGS.RiskRating.Min} 
                    max={FILTER_SETTINGS.RiskRating.Max} 
                    step={1} 
                    onChange={onRiskRatingChange}
                    initialMinValue={filter.RiskRating.Min} 
                    initialMaxValue={filter.RiskRating.Max}
                />
            </div>
            {/* monthly payment */}
            <div className={styles.FilterCriteriaSection}>
                <h3>Monthly Payment: ${filter.MonthlyPayment.Min} - {filter.MonthlyPayment.Max}</h3>
                <DualRangeSlider 
                    min={FILTER_SETTINGS.MonthlyPayment.Min} 
                    max={FILTER_SETTINGS.MonthlyPayment.Max} 
                    step={10} 
                    onChange={onMonthlyPaymentChange}
                    initialMinValue={filter.MonthlyPayment.Min} 
                    initialMaxValue={filter.MonthlyPayment.Max}
                />
            </div>
            {/* term */}
            <div className={styles.FilterCriteriaSection}>
                <h3>Term Length: {filter.TermLength.Min} - {filter.TermLength.Max} months</h3>
                <DualRangeSlider 
                    min={FILTER_SETTINGS.TermLength.Min} 
                    max={FILTER_SETTINGS.TermLength.Max} 
                    step={1} 
                    onChange={onTermLengthChange}
                    initialMinValue={filter.TermLength.Min} 
                    initialMaxValue={filter.TermLength.Max}
                />
            </div>
            {/* Yield */}
            <div className={styles.FilterCriteriaSection}>
                <h3>Yield: {filter.Yield.Min} - {filter.Yield.Max}%</h3>
                <DualRangeSlider 
                    min={FILTER_SETTINGS.Yield.Min} 
                    max={FILTER_SETTINGS.Yield.Max} 
                    step={1} 
                    onChange={onYieldChange}
                    initialMinValue={filter.Yield.Min} 
                    initialMaxValue={filter.Yield.Max}
                />
            </div>
        </div>
    );
}

export default FilterView;