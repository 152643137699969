import React, { useState, useRef, useEffect, useCallback } from 'react';
import { PieChart, Pie, ResponsiveContainer, LineChart, Line, XAxis, YAxis, Legend, } from 'recharts';

const ANIMATION_DURATION_MS = 1000;
const DATA_CHANGE_MS = 1250;
const PAUSE_DURATION_MS = 2000;

interface ExampleLoanData {
    month: number;
    interest: number;
    principal: number;
    balance: number;
}

interface ExampleLoan {
    principal: number;
    totalInterest: number;
    data: ExampleLoanData[];
}

// $1050, 12mo, 10$ apy, 5% origination fee, $57.74 interest
export const exampleLoan: ExampleLoan = {
    principal: 1050,
    totalInterest: 57.74,
    data: [
    { month: 1, interest: 8.75, principal: 83.56, balance: 966.44 },
    { month: 2, interest: 8.05, principal: 84.26, balance: 882.18 },
    { month: 3, interest: 7.35, principal: 84.96, balance: 797.22 },
    { month: 4, interest: 6.64, principal: 85.67, balance: 711.55 },
    { month: 5, interest: 5.93, principal: 86.38, balance: 625.17 },
    { month: 6, interest: 5.21, principal: 87.10, balance: 538.07 },
    { month: 7, interest: 4.48, principal: 87.83, balance: 450.24 },
    { month: 8, interest: 3.75, principal: 88.56, balance: 361.68 },
    { month: 9, interest: 3.01, principal: 89.30, balance: 272.38 },
    { month: 10, interest: 2.27, principal: 90.04, balance: 182.34 },
    { month: 11, interest: 1.52, principal: 90.79, balance: 91.55 },
    { month: 12, interest: 0.76, principal: 91.55, balance: 0.00 }
]}

export const AnimatedPieChart = (loan:ExampleLoan) => {
    const [, setCurrentIndex] = useState(0);
    const [displayData, setDisplayData] = useState<ExampleLoanData[]>(loan.data.slice(0, 1));
    const [isPaused, setIsPaused] = useState(false);
    const intervalRef = useRef<number | undefined>(undefined);  // Ref to store interval ID

    useEffect(() => {
        if (!isPaused) {
            const startAnimation = () => {
                intervalRef.current = window.setInterval(() => {
                    setCurrentIndex((prevIndex) => {
                        const newIndex = prevIndex < loan.data.length - 1 ? prevIndex + 1 : 0;
                        setDisplayData(loan.data.slice(0, newIndex + 1));

                        if (newIndex === loan.data.length - 1) {
                            clearInterval(intervalRef.current);
                            setIsPaused(true);
                            setTimeout(() => {
                                setIsPaused(false);
                            }, PAUSE_DURATION_MS);
                        }

                        return newIndex;
                    });
                }, DATA_CHANGE_MS);
            };

            startAnimation();
        }

        // Cleanup function to clear the interval
        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
        };
    }, [loan.data, isPaused]);

    return (
        <ResponsiveContainer>
            <PieChart>
                <Pie
                    dataKey="value"
                    data={[
                        { name: 'Total Remaining', value: displayData.length ? displayData[displayData.length - 1].balance : 0, fill: "var(--bg-color-2)" },
                        { name: 'Total Paid', value: displayData.reduce((sum, payment) => sum + payment.interest + payment.principal, 0), fill: "var(--pos-color)" },
                        { name: 'Next Payment', value: displayData.length ? displayData[displayData.length - 1].interest + displayData[displayData.length - 1].principal : 0, fill: "var(--cta-color)" }
                    ]}
                    cx="50%" cy="50%" outerRadius={"40%"} strokeWidth={0}
                    animationDuration={ANIMATION_DURATION_MS}
                />
                <Pie
                    dataKey="value"
                    data={[
                        { name: 'Principal Remaining', value: displayData.length ? loan.principal - displayData.reduce((sum, payment) => sum + payment.principal, 0) : 0, fill: "var(--bg-color-2)" },
                        { name: 'Principal Paid', value: displayData.reduce((sum, payment) => sum + payment.principal, 0), fill: "var(--pos-color)" },
                        { name: 'Next Principal', value: displayData.length ? displayData[displayData.length - 1].principal : 0, fill: "var(--cta-color)" }
                    ]}
                    cx="50%" cy="50%" innerRadius={"45%"} outerRadius={"65%"} strokeWidth={0}
                    animationDuration={ANIMATION_DURATION_MS}
                />
                <Pie
                    dataKey="value"
                    data={[
                        { name: 'Interest Remaining', value: displayData.length ? loan.totalInterest - displayData.reduce((sum, payment) => sum + payment.interest, 0) : 0, fill: "var(--bg-color-2)" },
                        { name: 'Interest Paid', value: displayData.reduce((sum, payment) => sum + payment.interest, 0), fill: "var(--pos-color)" },
                        { name: 'Next Interest', value: displayData.length ? displayData[displayData.length - 1].interest : 0, fill: "var(--cta-color)" }
                    ]}
                    cx="50%" cy="50%" innerRadius={"70%"} outerRadius={"90%"} strokeWidth={0}
                    labelLine={false}
                    animationDuration={ANIMATION_DURATION_MS}
                />
                <Legend verticalAlign='top' payload={[{value: 'next', color: 'var(--cta-color)'}, {value: 'paid', color: 'var(--pos-color)'}]}/>
            </PieChart>
        </ResponsiveContainer>
    );
};

export const AnimatedLineChart = (loan:ExampleLoan) => {
    const [, setCurrentIndex] = useState(0);
    const [displayData, setDisplayData] = useState<ExampleLoanData[]>(loan.data.slice(0, 1));
    const [isPaused, setIsPaused] = useState(false);
    const intervalRef = useRef<number | undefined>(undefined);  // Ref to store interval ID

    useEffect(() => {
        if (!isPaused) {
            const startAnimation = () => {
                intervalRef.current = window.setInterval(() => {
                    setCurrentIndex((prevIndex) => {
                        const newIndex = prevIndex < loan.data.length - 1 ? prevIndex + 1 : 0;
                        setDisplayData(loan.data.slice(0, newIndex + 1));

                        if (newIndex === loan.data.length - 1) {
                            clearInterval(intervalRef.current);
                            setIsPaused(true);
                            setTimeout(() => {
                                setIsPaused(false);
                            }, PAUSE_DURATION_MS);
                        }

                        return newIndex;
                    });
                }, DATA_CHANGE_MS);
            };

            startAnimation();
        }

        // Cleanup function to clear the interval
        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
        };
    }, [loan.data, isPaused]);

    return (
        <ResponsiveContainer>
            <LineChart
                width={500}
                height={300}
                // lol ew @ this single line function
                data={displayData.map((val, idx)=>{
                        const interestSum = displayData.slice(0,idx).reduce((sum, payment)=>sum+payment.interest, 0);
                        const principalSum = displayData.slice(0,idx).reduce((sum, payment)=>sum+payment.principal, 0);
                        return {month: idx + 1, interest: val.interest + interestSum, principal: val.principal + principalSum};
                    })//.concat(Array.apply(null, Array(loan.data.length - displayData.length)).map((_: any, idx: number)=>{return {month: displayData.length + idx + 1}}))
                }
                margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
            >
                <XAxis dataKey="month" /*label={"Month"}*//>
                <YAxis hide={true} yAxisId="interest" domain={[0, loan.totalInterest]}/>
                <YAxis hide={true} yAxisId="principal" orientation="right" domain={[0, loan.principal]}/>
                <Line yAxisId="interest" type="monotone" dataKey="interest" stroke="var(--cta-color)" strokeWidth={"3px"} dot={false} fill="var(--cta-color)" animationDuration={ANIMATION_DURATION_MS}/>
                <Line yAxisId="principal" type="monotone" dataKey="principal" stroke="var(--pos-color)" strokeWidth={"3px"} dot={false} fill="var(--pos-color)" animationDuration={ANIMATION_DURATION_MS}/>
                <Legend verticalAlign={"top"} iconType={"plainline"}/>
            </LineChart>
        </ResponsiveContainer>
    );
}