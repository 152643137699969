// get the window's query parameters
export const getQueryParams = (): URLSearchParams => {
	return new URLSearchParams(window.location.search);
};

export const getWindowDimensions = () => {
	const { innerWidth: width, innerHeight: height } = window;
	return {
		width,
		height
	};
}