import Cookies from 'js-cookie'; //npm i --save-dev @types/js-cookie
import { getUserbyIdAndEmail, updateUserData } from './api.ts';

const USER_DATA_COOKIE_KEY = "userData";

export interface LenderLoanCriteria {
    minimumRiskRating: number; // minimum risk level lender will lend to
    maximumRiskRating: number; // maximum risk level lender will lend to
    cashLendable: number; // maximum cash lender is willing to lend to this criteria
    minimumTerm: number; // minimum term lender will lend amount for
    maximumTerm: number; // maximum term lender will lend amount for
    portionWillingToFund: number; //scaled percent of cash that will go into any one loan
}

export interface PlaidBankAccount {
    accountName: string;
    isDepositWithdraw: boolean;
    canBeDepositWithdraw: boolean;
}

export interface PlaidLinkedBank {
    bankName:   string
	isMainBank: boolean
	accounts:   PlaidBankAccount[]
}

// actually a DTO UserBioData from the server
export interface UserBioData {
    firstName: string;
    lastName: string;
    dob: string;
    phone: string;
    address: string;
    typeOfResidence: string;
    state: string;
    zip: string;
    employmentStatus: string;
    employmentVerified: boolean; // read only, server will change this value
    incomeBeforeTaxes: number;
    incomeVerified: boolean; // read only, server will change this value
}

// actually a DTO UserLoanData from the server
export interface UserLoanData {
    loanCriteria: LenderLoanCriteria[];
}

// actually a DTO User from the server
export interface User {
    id: string;
    email: string;
    bio: UserBioData;
    
    isPlaidAccess: boolean;
    plaidLinkedBanks: PlaidLinkedBank[];
    transactionDashboardEnabled: boolean; //true if the user has access to the transactionDashboard
    isIdentityVerified: boolean; // read only, server will change this value

    loanData: UserLoanData
}

// Initial empty user data
export class UserImpl implements User {
    id: string;
    email: string;
    bio: UserBioData;
    isPlaidAccess: boolean;
    plaidLinkedBanks: PlaidLinkedBank[];
    transactionDashboardEnabled: boolean;
    isIdentityVerified: boolean;
    loanData: UserLoanData;
  
    constructor() {
        this.id = '';
        this.email = "";
        this.bio = {
            firstName: "",
            lastName: "",
            dob: "",
            phone: "",
            address: "",
            typeOfResidence: "",
            state: "",
            zip: "",
            employmentStatus: "",
            employmentVerified: false,
            incomeBeforeTaxes: 0,
            incomeVerified: false,
        };
        this.isPlaidAccess = false;
        this.plaidLinkedBanks = []
        this.transactionDashboardEnabled = false;
        this.isIdentityVerified = false;
        this.loanData = {
            loanCriteria: [],
        }
    }
}

export const clearUserData = () => {
    Cookies.remove(USER_DATA_COOKIE_KEY);
}

export const initSaveUserDataToCookieOnly = (newUserData: User) => {
    Cookies.set(USER_DATA_COOKIE_KEY, JSON.stringify(newUserData), { expires: 7 });
}

export const saveUserData = async (newUserData: User) => {
    const savedUserDataStr = Cookies.get(USER_DATA_COOKIE_KEY);
    const savedUserData = savedUserDataStr ? JSON.parse(savedUserDataStr) as Partial<User> : {};
    const modifiedFields: Partial<User> = {};
    // Helper to detect changes in nested objects
    const detectChanges = (newData: any, savedData: any) => {
        const changes: any = {};
        for (const key in newData) {
            if (newData[key] !== savedData?.[key]) {
                changes[key] = newData[key];
            }
        }
        return changes;
    };
    // Helper type guards
    const isNumber = (value: any): value is number => typeof value === 'number';
    const isString = (value: any): value is string => typeof value === 'string';
    const isBoolean = (value: any): value is boolean => typeof value === 'boolean';
    const isArray = (value: any): value is any[] => Array.isArray(value);
    // Check for modified fields and ensure type preservation
    for (const key in newUserData) {
        if (key === "id") {
            continue;
        }
        const newValue = newUserData[key as keyof User];
        const savedValue = savedUserData[key as keyof User];
        // Handle nested bio object
        if (key === "bio" && newValue && savedValue) {
            const modifiedBio = detectChanges(newValue, savedValue);
            if (Object.keys(modifiedBio).length > 0) {
                modifiedFields[key as keyof User] = modifiedBio as any;
            }
        }
        // Handle nested loanData object (specifically loanCriteria)
        else if (key === "loanData" && newValue && savedValue) {
            const modifiedLoanData = detectChanges(newValue, savedValue);
            if (Object.keys(modifiedLoanData).length > 0) {
                modifiedFields[key as keyof User] = modifiedLoanData.loanCriteria;
            }
        }
        // If the field is an array (like plaidLinkedBanks), handle it differently
        if (isArray(newValue)) {
            if (JSON.stringify(newValue) !== JSON.stringify(savedValue)) {
                // Include the array, even if it's empty (which could mean removing banks)
                modifiedFields[key as keyof User] = newValue as any;
            }
        } 
        // Handle non-array fields (numbers, strings, booleans)
        else if (newValue !== savedValue && newValue !== undefined) {
            if (isNumber(newValue) && isNumber(savedValue)) {
                modifiedFields[key as keyof User] = newValue as any;
            } else if (isString(newValue) && isString(savedValue)) {
                modifiedFields[key as keyof User] = newValue as any;
            } else if (isBoolean(newValue) && isBoolean(savedValue)) {
                modifiedFields[key as keyof User] = newValue as any;
            } else {
                modifiedFields[key as keyof User] = newValue as any;
            }
        }
    }
    // If there are modified fields, save the new data
    if (Object.keys(modifiedFields).length > 0) {
        const updatedUserData = { ...savedUserData, ...modifiedFields };
        Cookies.set(USER_DATA_COOKIE_KEY, JSON.stringify(updatedUserData), { expires: 7 });
        // await updateUserData(newUserData.id, modifiedFields); // send only the modified fields to the backend server for updating
        await updateUserData(newUserData.id, newUserData);
    }
};

/**
 * Get's the user's latest data from the server and saves it in the browser's cookie jar
 * @param userEmail user's email address
 * @param userId user's encrypted ID
 * @returns the USer DTO
 */
export const getServerUserData = async (userEmail: string, userId: string): Promise<User> => {
    const user = await getUserbyIdAndEmail(userEmail, userId);
    Cookies.set(USER_DATA_COOKIE_KEY, JSON.stringify(user), { expires: 7 });
    return user;
}

/**
 * Get's the user's data from the cookie saved locally
 * @returns user saved in the server's cookie jar
 */
export const getUserCookieData = (): User | null => {
    const userDataString = Cookies.get(USER_DATA_COOKIE_KEY);
    let userData = null;
    if(userDataString) {
        userData = JSON.parse(userDataString);
    }
    return userData;
}

export const isUserSignedIn = (): boolean => {
    return Cookies.get(USER_DATA_COOKIE_KEY) ? true : false;
}