export interface LoanPaymentData {
	principal: number
	interest:  number
	date:      Date
}

export interface Loan {
    id:                      string
	loanTotalInCents:        number              //total amount of loan, excludes interest
	loanAmountInCents:       number              //the amount of the loan, excluding origination
	loanPaidInCents:         number              //how much of the total loan has been paid, excludes interest
	originationFeeInCents:   number              //origination fee as a part of the loan amount
	expectedInterestInCents: number              //how much interest will be paid on the loan
	loanDurationInDays:      number              //how many days this loan is expected to be open
	loanInterestRate:        number              //kept as an int scaled by helpers.scaleFactor to avoid precision errors
	loanApr:                 number              //scaled percent value of APR
	loanTerm:                number              //number of months in loan term
	loanStartDate:           Date                //when this loan was opened
	loanEndDate:             Date                //when this loan is expted to end
	portion:                 number              //portion of loan funded by lender (only populated when a lender retquests data on loans they've funded)
	monthlyPayment:          number              //scaled monthly payment for loan
	expectedPayments:        LoanPaymentData[]   //expected payments over term of loan
	actualPayments:          LoanPaymentData[]   //actual payments made over term of loan
	isActive:                boolean             //true if the loan was accepted, false if not
	isComplete:              boolean             //true if the loan is payed off
	isDefault:               boolean             //true if this loan is in default
	isForSale:               boolean             //true if this loan is for sale
	defaultAmout:            number              //the amount that went unpaid
}

export enum LOAN_DETAIL_CONSTS {
	MAX_LOANABLE = 5000, //amounts over $5K need a Suspicious Activity Report, amounts over $10K need a Currency Transaction Report filed within 15 days
	STEP_LOANABLE = 50,
	MIN_LOANABLE = 250,
	MIN_LOAN_TERM = 2,
	MAX_LOAN_TERM = 24,
	MIN_MONTHLY_PAYMENT = 50 // loan monthly payments will be no lower than this amount
}

export class LoanCalc {
	static scaleFactor = 1000;
	static originationPercent = 0.05;

	// PMT calculates the payment for a loan based on constant payments and a constant interest rate.
	// rate is the interest rate per period (scaled by scaleFactor), nper is the number of periods, pv (scaled by scaleFactor) is the present value (loan amount).
	static PMT(rate: number, nper: number, pv: number): number {
		const r = rate / LoanCalc.scaleFactor / 100;
		const pvF = pv / LoanCalc.scaleFactor;
		const pmt = (pvF * r) / (1 - Math.pow(1 + r, -nper));
		return Math.round(pmt * LoanCalc.scaleFactor);
	}
  
	// IPMT calculates the interest payment for a given period of a loan.
	// rate is the interest rate per period (scaled by scaleFactor), per is the period for which the interest is calculated, nper is the number of periods, pv (scaled by scaleFactor) is the present value (loan amount).
	static IPMT(rate: number, per: number, nper: number, pv: number): number {
		const r = rate / LoanCalc.scaleFactor / 100;
		const pvF = pv / LoanCalc.scaleFactor;
		const pmt = LoanCalc.PMT(rate, nper, pv) / LoanCalc.scaleFactor;
		const remainingBalance = pvF * Math.pow(1 + r, per - 1) - (pmt * (Math.pow(1 + r, per - 1) - 1)) / r;
		const ipmt = remainingBalance * r;
		return Math.round(ipmt * LoanCalc.scaleFactor);
	}
  
	// CUMIPMT calculates the cumulative interest paid on a loan between two periods.
	// rate is the interest rate per period (scaled by scaleFactor), nper is the number of periods, pv (scaled by scaleFactor) is the present value (loan amount), startPeriod is the starting period, endPeriod is the ending period.
	static CUMIPMT(rate: number, nper: number, pv: number, startPeriod: number, endPeriod: number): number {
		let totalInterest = 0;
		for (let per = startPeriod; per <= endPeriod; per++) {
			totalInterest += LoanCalc.IPMT(rate, per, nper, pv);
		}
		return totalInterest;
	}
  
	// PPMTArray calculates the principal payment for each period of a loan.
	// rate is the interest rate per period (scaled by scaleFactor), nper is the number of periods, pv (scaled by scaleFactor) is the present value (loan amount).
	static PPMTArray(rate: number, nper: number, pv: number): number[] {
		const pmt = LoanCalc.PMT(rate, nper, pv);
		const ppmtArray: number[] = new Array(nper).fill(0);
		let remainingBalance = pv / LoanCalc.scaleFactor;
		for (let per = 1; per <= nper; per++) {
			const ipmt = LoanCalc.IPMT(rate, per, nper, pv) / LoanCalc.scaleFactor;
			const ppmt = pmt / LoanCalc.scaleFactor - ipmt;
			ppmtArray[per - 1] = Math.round(ppmt * LoanCalc.scaleFactor);
			remainingBalance -= ppmt;
		}
		return ppmtArray;
	}
  
	// IPMTArray calculates the interest payment for each period of a loan.
	// rate is the interest rate per period (scaled by scaleFactor), nper is the number of periods, pv (scaled by scaleFactor) is the present value (loan amount).
	static IPMTArray(rate: number, nper: number, pv: number): number[] {
		const ipmtArray: number[] = new Array(nper).fill(0);
		let remainingBalance = pv / LoanCalc.scaleFactor;
		for (let per = 1; per <= nper; per++) {
			const ipmt = remainingBalance * (rate / LoanCalc.scaleFactor / 100);
			ipmtArray[per - 1] = Math.round(ipmt * LoanCalc.scaleFactor);
			const ppmt = LoanCalc.PMT(rate, nper, pv) / LoanCalc.scaleFactor - ipmt;
			remainingBalance -= ppmt;
		}
		return ipmtArray;
	}

	/**
	 * 
	 * @param loanAmount unscaled amount of the loan eg: 100 for $100
	 * @param term term of loan in months (unscaled)
	 * @param interestRate scaled interest rate of loan eg: 10% is 10 * LoanCalc.scaleFactor
	 * @returns 
	 */
	static NewEstimatedLoan(loanAmount: number, term: number, interestRate: number): Loan | null {
		const originationFee =(LoanCalc.originationPercent * loanAmount) * LoanCalc.scaleFactor;
		const totalLoanAmount = (loanAmount * LoanCalc.scaleFactor) + originationFee;
		const monthlyPayment = LoanCalc.PMT((interestRate)/12, term, totalLoanAmount);
		const now = new Date();
        const loanStartDate = new Date(now.getFullYear(), now.getMonth() + 1, 1); // First day of next month

		// if the monthly payment is below the minimum, don't calculate it
		if(!(monthlyPayment/LoanCalc.scaleFactor >= LOAN_DETAIL_CONSTS.MIN_MONTHLY_PAYMENT)) {
			return null
		}
		// Calculate loan end date by adding the term (in months) to the loan start date
		const loanEndDate = new Date(loanStartDate);
		loanEndDate.setMonth(loanEndDate.getMonth() + term);

		// Calculate the number of days between loan start date and loan end date
		const timeDiff = loanEndDate.getTime() - loanStartDate.getTime();
		const numberOfDays = Math.ceil(timeDiff / (1000 * 3600 * 24)); // Convert from milliseconds to days
		const interestPayments = LoanCalc.IPMTArray(interestRate/12, term, totalLoanAmount);
		const principalPayments = LoanCalc.PPMTArray(interestRate/12, term, totalLoanAmount);
		const totalInterest = interestPayments.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
		const totalCost = totalInterest + originationFee;
		// const apr = ((totalCost/ (loanDetails.Amount * LoanCalc.scaleFactor)) / numberOfDays * 365) * 100 * LoanCalc.scaleFactor;
		const apr = Math.round((totalCost / loanAmount) * (365 / numberOfDays) * 100)

		const newLoan: Loan = {
			id: "",
			loanTotalInCents: totalLoanAmount,
			loanAmountInCents: (loanAmount * LoanCalc.scaleFactor),
			loanPaidInCents: 0,
			originationFeeInCents: originationFee,
			expectedInterestInCents: totalInterest,
			loanDurationInDays: numberOfDays,
			loanInterestRate: interestRate,
			loanApr: apr,
			loanTerm: term,
			loanStartDate: loanStartDate,
			loanEndDate: loanEndDate,
			portion: 0,
			monthlyPayment: monthlyPayment,
			expectedPayments: interestPayments.map((_, i) => {
				// Clone the loanStartDate for each iteration to avoid mutation
				const paymentDate = new Date(loanStartDate);
				// Increment the month by the index (i)
				paymentDate.setMonth(loanStartDate.getMonth() + i);
				const loanPaymentdata: LoanPaymentData = {
					principal: principalPayments[i],
					interest: interestPayments[i],
					date: paymentDate // Set the payment date
				}
				return loanPaymentdata;
			}),
			actualPayments: [],
			isActive: false,
			isComplete: false,
			isDefault: false,
			isForSale: false,
			defaultAmout: 0
		}

		return newLoan;
	}
}
  
